import {
    Avatar,
    Box,
    Container,
    Grid,
    Link,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Page from '../../components/Page';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import { getPremsa, publicUrl } from '../../database/API';
import { GetApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 200,
        backgroundColor: theme.palette.background.default,
    },
    dossierWrap: {
        padding: 20,
        display: 'flex',
        borderRadius: 30,
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: '0.5s',
        border: '2px solid white',
        '& .MuiTypography-h3': {
            color: 'white',
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
        '&:hover': {
            color: theme.palette.background.default,
            backgroundColor: 'white',
            boxShadow: 'none',
            '& .MuiTypography-h3': {
                color: theme.palette.background.default,
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.background.default,
            },
        },
    },
    nomWrap: {
        alignItems: 'center',
        display: 'flex',
    },
    abbr: {
        backgroundColor: 'white',
        marginRight: 20,
    },
}));
const QueEs = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [dossiers, setDossiers] = useState();
    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery('(min-width:960px)');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { data } = await getPremsa();
            setDossiers(data);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Page
            title={t('Dossiers de premsa')}
            description={t(
                'Dossiers per a la premsa amb informació sobre La Patum de Berga'
            )}
        >
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Header title={t('Dossiers de premsa')} />
                        <Grid container spacing={10}>
                            {!loading ? (
                                dossiers?.map((item) => {
                                    return (
                                        <Grid
                                            item
                                            md={6}
                                            style={{ width: '100%' }}
                                        >
                                            <Link
                                                className={classes.dossierWrap}
                                                href={`${publicUrl}imatges/${item.pdf}`}
                                                target="_blank"
                                                underline="none"
                                            >
                                                <Box
                                                    className={classes.nomWrap}
                                                >
                                                    <Avatar
                                                        className={classes.abbr}
                                                    >
                                                        {item.idioma.abb.toUpperCase()}
                                                    </Avatar>

                                                    <Typography variant="h3">
                                                        {item.nom}
                                                    </Typography>
                                                </Box>

                                                <GetApp />
                                            </Link>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Box
                                    style={{
                                        width: '100%',
                                        height: 300,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        textAlign: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Loading />
                                </Box>
                            )}
                        </Grid>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default QueEs;

import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Divider,
    Grid,
    Link,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../lang';
import Footer from '../../components/Footer';
import Page from '../../components/Page';
import { useLocation, useNavigate } from 'react-router';
import { getMiniNoticies, publicUrl } from '../../database/API';
import { ArrowBack, GetApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 200,
    },
    image: {
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
        marginBottom: 20,
    },
    destacats: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 20,
        padding: 30,
    },
    imatge: {
        width: '100%',
        height: 150,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
        marginBottom: 20,
    },
    noticia: {
        backgroundColor: 'white',
        cursor: 'pointer',
        padding: 15,
        borderRadius: 20,
        border: '2px solid white',
        transition: '0.3s',
        '&:hover': {
            backgroundColor: 'transparent',
            '& .MuiTypography-body1': {
                color: 'white',
            },
        },
    },
    data: {
        color: theme.palette.text.primary,
        fontSize: 11,
        paddingBottom: 10,
    },
    cos: {
        '& p span': {
            fontSize: '1.1rem  !important',
        },
        '& span': {
            fontFamily: 'Varela Round !important',
            fontSize: '1.1rem  !important',
        },
    },
    dossierWrap: {
        padding: 10,
        display: 'flex',
        borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: '0.5s',
        border: '2px solid white',
        width: '100%',
        backgroundColor: 'white',
        '& .MuiTypography-body1': {
            color: theme.palette.background.default,
            fontSize: 14,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.background.default,
        },
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.background.default,
            border: '2px solid white',
            boxShadow: 'none',
            '& .MuiTypography-body1': {
                color: 'white',
            },
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
        },
    },
}));

const SingleNoticies = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [noticies, setNoticies] = useState();
    const matches = useMediaQuery('(min-width:960px)');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    useEffect(() => {
        const get = async () => {
            const { data } = await getMiniNoticies(i18n.language);
            setNoticies(data);
        };
        get();
    }, [t]);

    return (
        <Page title={t('Notícies')}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Link
                                    onClick={() =>
                                        navigate('/actualitat/noticies/')
                                    }
                                    style={{
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <ArrowBack
                                        style={{
                                            fontSize: 12,
                                            marginTop: 3,
                                        }}
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{ marginTop: 5 }}
                                    >
                                        Tornar
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item md={8}>
                                <Typography variant="h5">
                                    {state.titol}
                                </Typography>
                                <Box my={2} />

                                <Divider
                                    style={{
                                        backgroundColor: '#d9594e',
                                        height: 2,
                                    }}
                                />
                                <Box my={1} />

                                <Typography variant="caption">
                                    {i18n
                                        .moment(state.created_at)
                                        .format('DD/MM/Y')}
                                </Typography>
                                <Box py={4}>
                                    <img
                                        alt="Foto"
                                        src={`${publicUrl}imatges/${state.foto}/noticies`}
                                        className={classes.image}
                                    />
                                </Box>
                                <Box my={3} />
                                <Typography
                                    variant="body1"
                                    style={{
                                        whiteSpace: 'none',
                                        textAlign: 'justify',
                                    }}
                                >
                                    <div
                                        className={classes.cos}
                                        dangerouslySetInnerHTML={{
                                            __html: state.cos,
                                        }}
                                    ></div>
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                {(state.adjunt_1 || state.adjunt_2) && (
                                    <>
                                        <Box className={classes.destacats}>
                                            <Typography
                                                variant="h4"
                                                style={{ color: 'white' }}
                                            >
                                                {t('Documents')}
                                            </Typography>
                                            <Box my={2} />

                                            <Grid container spacing={3}>
                                                {state.adjunt_1 && (
                                                    <Link
                                                        className={
                                                            classes.dossierWrap
                                                        }
                                                        href={`${publicUrl}imatges/${state.adjunt_1}/noticies`}
                                                        target="_blank"
                                                        underline="none"
                                                    >
                                                        <Box
                                                            className={
                                                                classes.nomWrap
                                                            }
                                                        >
                                                            <Typography variant="body1">
                                                                {state.nom_1}
                                                            </Typography>
                                                        </Box>

                                                        <GetApp />
                                                    </Link>
                                                )}

                                                {state.adjunt_2 && (
                                                    <Link
                                                        className={
                                                            classes.dossierWrap
                                                        }
                                                        href={`${publicUrl}imatges/${state.adjunt_2}/noticies`}
                                                        target="_blank"
                                                        underline="none"
                                                    >
                                                        <Box
                                                            className={
                                                                classes.nomWrap
                                                            }
                                                        >
                                                            <Typography variant="body1">
                                                                {state.nom_2}
                                                            </Typography>
                                                        </Box>

                                                        <GetApp />
                                                    </Link>
                                                )}

                                                {state.adjunt_3 && (
                                                    <Link
                                                        className={
                                                            classes.dossierWrap
                                                        }
                                                        href={`${publicUrl}imatges/${state.adjunt_3}/noticies`}
                                                        target="_blank"
                                                        underline="none"
                                                    >
                                                        <Box
                                                            className={
                                                                classes.nomWrap
                                                            }
                                                        >
                                                            <Typography variant="body1">
                                                                {state.nom_3}
                                                            </Typography>
                                                        </Box>

                                                        <GetApp />
                                                    </Link>
                                                )}
                                            </Grid>
                                        </Box>
                                        <Box my={3} />
                                    </>
                                )}
                                <Box className={classes.destacats}>
                                    <Typography
                                        variant="h4"
                                        style={{ color: 'white' }}
                                    >
                                        {t('Destacades')}
                                    </Typography>
                                    <Box my={2} />

                                    <Grid container spacing={3}>
                                        {noticies?.map((item) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    onClick={() =>
                                                        navigate(
                                                            '/actualitat/noticies/' +
                                                                item.id,
                                                            {
                                                                state: item,
                                                            }
                                                        )
                                                    }
                                                >
                                                    <Box
                                                        pt={3}
                                                        className={
                                                            classes.noticia
                                                        }
                                                    >
                                                        <Box
                                                            className={
                                                                classes.imatge
                                                            }
                                                            style={{
                                                                backgroundImage: `url(${publicUrl}imatges/${item.foto}/noticies)`,
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.data
                                                            }
                                                        >
                                                            {i18n
                                                                .moment(
                                                                    item.created_at
                                                                )
                                                                .format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {item.titol}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default SingleNoticies;

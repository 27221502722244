import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
    AppBar,
    Box,
    Drawer,
    FormControl,
    Hidden,
    IconButton,
    InputBase,
    List,
    MenuItem,
    Select,
    Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Close } from '@material-ui/icons';
import navBarStyle from './NavBarStyle';
import NavBarItem from './NavBarItem';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import i18n from '../lang';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Patum } from '../theme/icons/logo.svg';
import Logo from '../components/Logo';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '0',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        color: 'white',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: 'Varela Round',
        '&:focus': {
            borderRadius: 4,
            borderColor: 'transparent',
            boxShadow: '0',
            backgroundColor: 'transparent',
        },
    },
}))(InputBase);

const NavBar = () => {
    const classes = navBarStyle();
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [menu, setMenu] = useState(null);
    const [lang, setLang] = useState(i18n.language);
    const { t } = useTranslation();
    const location = useLocation();

    // useEffect(() => {
    //     console.log(i18n);
    // });
    const items = [
        {
            title: t('Inici'),
            to: '/',
        },
        {
            title: t('que_es'),
            to: '/que-es',
        },
        {
            title: t('Història'),
            to: '/historia',
        },
        {
            title: t('Protagonistes'),
            to: '',
            subMenu: [
                { title: t('Comparses'), to: '/protagonistes/comparses' },
                { title: t('Música'), to: '/protagonistes/musica' },
                {
                    title: t('Administradors/es'),
                    to: '/protagonistes/administradors',
                },
                {
                    title: t('patum_infantil'),
                    to: '/protagonistes/patum-infantil',
                },
                { title: 'Patumaires', to: '/protagonistes/patumaires' },
            ],
        },
        {
            title: t('patum_any'),
            to: '/patum-tot-lany',
        },
        {
            title: t('vides_de_patum'),
            to: '/vides-de-patum',
        },
        {
            title: t('Actualitat'),
            to: '',
            subMenu: [
                { title: t('Notícies'), to: '/actualitat/noticies' },
                { title: t('Agenda'), to: '/actualitat/agenda' },
            ],
        },
        {
            title: t('Patronat'),
            to: '/patronat',
        },
        {
            title: t('Formularis'),
            to: '/formularis',
        },
        {
            title: t('Premsa'),
            to: '',
            subMenu: [
                { title: t('Dossiers'), to: '/premsa/dossiers' },
                { title: t('Acreditacions'), to: '/premsa/acreditacions' },
            ],
        },
    ];

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    const openMenu = (event) => {
        setMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setMenu(null);
    };

    const changeLang = (lang) => {
        console.log(lang);
        i18n.changeLanguage(lang);
        setMenu(false);
        setLang(lang);
    };

    return (
        <div className={classes.root}>
            <AppBar
                elevation={0}
                className={clsx(
                    scrollY > (location.pathname === '/' ? 500 : 100)
                        ? classes.onScroll
                        : location.pathname === '/'
                        ? classes.onTopHome
                        : classes.onTop,
                    classes.navBar
                )}
            >
                <Toolbar className={classes.toolbar}>
                    <Hidden lgUp>
                        <IconButton
                            color="black"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={openMenu}
                            className={classes.buttonDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            open={Boolean(menu)}
                            onClose={closeMenu}
                            disableScrollLock={true}
                        >
                            <IconButton>
                                <Close onClick={closeMenu} />
                            </IconButton>
                            <List>
                                {items?.map((item) => (
                                    <NavBarItem
                                        item={item}
                                        key={item.title}
                                        closeMenu={closeMenu}
                                        mobile={true}
                                    />
                                ))}
                            </List>
                        </Drawer>
                    </Hidden>
                    <Box flexGrow={1} />
                    <Hidden mdDown>
                        {items?.map((item) => (
                            <NavBarItem
                                item={item}
                                key={item.title}
                                mobile={false}
                                closeMenu={closeMenu}
                            />
                        ))}
                    </Hidden>
                    <Hidden lgUp>
                        <Logo width={100} height={60} icon={Patum} />
                    </Hidden>
                    <Box flexGrow={1} />
                    <FormControl className={classes.margin}>
                        <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={lang}
                            input={<BootstrapInput />}
                            inputProps={{
                                classes: {
                                    icon: classes.arrow,
                                },
                            }}
                            MenuProps={{ disableScrollLock: true }}
                        >
                            <MenuItem
                                className={classes.menuItem}
                                value={'ca'}
                                onClick={() => changeLang('ca')}
                            >
                                Ca
                            </MenuItem>
                            <MenuItem
                                className={classes.menuItem}
                                value={'es'}
                                onClick={() => changeLang('es')}
                            >
                                Es
                            </MenuItem>
                            <MenuItem
                                className={classes.menuItem}
                                value={'en'}
                                onClick={() => changeLang('en')}
                            >
                                En
                            </MenuItem>
                            <MenuItem
                                className={classes.menuItem}
                                value={'fr'}
                                onClick={() => changeLang('fr')}
                            >
                                Fr
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Toolbar>
            </AppBar>
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar;

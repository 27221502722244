import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    InputBase,
    makeStyles,
    TextField,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../lang';
import { getNoticies, publicUrl } from '../../database/API';
import moment from 'moment';
import Footer from '../../components/Footer';
import Page from '../../components/Page';
import Header from '../../components/Header';
import Pagination from '@material-ui/lab/Pagination';
import { animateScroll } from 'react-scroll';
import { useNavigate } from 'react-router';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import Loading from '../../components/Loading';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 200,
        backgroundColor: theme.palette.background.default,
    },
    imatge: {
        width: '100%',
        height: 150,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
        marginBottom: 20,
    },
    noticia: {
        backgroundColor: 'white',
        cursor: 'pointer',
        padding: 15,
        borderRadius: 20,
        border: '2px solid white',
        transition: '0.3s',
        '&:hover': {
            backgroundColor: 'transparent',
            '& .MuiTypography-body1': {
                color: 'white',
            },
        },
    },
    data: {
        color: theme.palette.text.primary,
        fontSize: 11,
        paddingBottom: 10,
    },
    pag: {
        '& .MuiPagination-ul': {
            '& .MuiPaginationItem-root': {
                backgroundColor: '#ffffff',
            },
            '& .Mui-selected': {
                backgroundColor: 'transparent',
                border: '2px solid white',
                color: 'white',
            },
        },
    },
    input: {
        width: '100%',
    },
    search: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        padding: 5,
    },
    searchIcon: {
        color: '#b1b1b1',
        marginRight: 10,
    },
    toolbox: {
        alignItems: 'center',
    },
}));

const Noticies = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [noticies, setNoticies] = useState();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [cercar, setCercar] = useState('');
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:960px)');
    const [{ opacity }, set] = useSpring(() => ({
        opacity: 0,
    }));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { data } = await getNoticies(i18n.language, page);
            console.log(data);
            setNoticies(data.data);
            setTotal(data.last_page);
            setLoading(false);
            set.start({ opacity: loading ? 0 : 1 });
        };
        get();
    }, [loading, page, set, t]);

    useEffect(() => {
        const get = async () => {
            const { data } = await getNoticies(i18n.language, page, cercar);
            console.log(data.data);
            setNoticies(data.data);
            setTotal(data.last_page);
            setLoading(false);
            set.start({ opacity: loading ? 0 : 1 });
        };
        get();
    }, [cercar, loading, page, set]);

    const handleChange = (event) => {
        setCercar(event.target.value);
    };

    return (
        <Page title={t('Notícies')}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Header title={t('Notícies')} />
                        <Grid container className={classes.toolbox} spacing={4}>
                            <Grid item md={6}>
                                <Box className={classes.search}>
                                    <Search className={classes.searchIcon} />
                                    <InputBase
                                        label="Cercar"
                                        value={cercar}
                                        onChange={handleChange}
                                        className={classes.input}
                                        placeholder={t('Cercar')}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box
                                    my={4}
                                    justifyContent="flex-end"
                                    display="flex"
                                >
                                    <Pagination
                                        page={page}
                                        count={total}
                                        className={classes.pag}
                                        onChange={(event, page) =>
                                            setPage(page)
                                        }
                                        onClick={() =>
                                            animateScroll.scrollToTop()
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        {loading && (
                            <Box
                                style={{
                                    width: '100%',
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Loading />
                            </Box>
                        )}
                        <animated.div style={{ opacity }}>
                            <Grid container spacing={4}>
                                {noticies?.map((item) => {
                                    return (
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            style={{ display: 'flex' }}
                                            onClick={() =>
                                                navigate(
                                                    '/actualitat/noticies/' +
                                                        item.id,
                                                    {
                                                        state: item,
                                                    }
                                                )
                                            }
                                        >
                                            <Box
                                                pt={3}
                                                className={classes.noticia}
                                            >
                                                <Box
                                                    className={classes.imatge}
                                                    style={{
                                                        backgroundImage: `url(${publicUrl}imatges/${item.foto}/noticies)`,
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    className={classes.data}
                                                >
                                                    {moment(
                                                        item.created_at
                                                    ).format('DD/MM/YYYY')}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {item.titol}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Box
                                my={4}
                                justifyContent="flex-end"
                                display="flex"
                            >
                                <Pagination
                                    page={page}
                                    count={total}
                                    className={classes.pag}
                                    onClick={() => animateScroll.scrollToTop()}
                                    onChange={(event, page) => setPage(page)}
                                />
                            </Box>
                        </animated.div>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default Noticies;

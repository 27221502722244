import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Page from '../../components/Page';
import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import { ReactComponent as Musica } from '../../theme/icons/musica.svg';
import theme from '../../theme';
import Divide from '../../components/Divide';
import Reproductor from '../../components/Reproductor';
import { Element } from 'react-scroll';
import Spotify from '../../components/Spotify';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
    },
    main2: {
        paddingBottom: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'white',
        alignContent: 'center',
    },
    mainM: {
        paddingTop: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
    },
    image: {
        width: '100%',
    },
    header: {
        alignItems: 'flex-end',
    },
}));

const MusicaScreen = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width:960px)');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={t('Música')}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t('La Música')} />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Container className={classes.root}>
                        <Grid container spacing={10} className={classes.header}>
                            <Grid item md={6}>
                                <Logo
                                    icon={Musica}
                                    color={theme.palette.text.primary}
                                    height={200}
                                    width={200}
                                />
                                <Box py={3} />
                                <Typography variant="h2">
                                    {t('El component fonamental')}
                                </Typography>
                            </Grid>
                            <Grid item md={6} style={{ textAlign: 'right' }}>
                                <Spotify />
                            </Grid>
                        </Grid>
                        <Divide color />
                        <Box py={3} />

                        <Grid container spacing={10}>
                            <Grid item md={6}>
                                <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    {t('musica_1')}
                                </Typography>

                                <Box py={5}>
                                    <img
                                        alt="Dissabte 1"
                                        src={`${process.env.PUBLIC_URL}/images/musica.jpg`}
                                        className={classes.image}
                                    />
                                    <Typography>
                                        Foto: La Fotogràfica
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} style={{ width: '100%' }}>
                                <Reproductor />
                            </Grid>
                        </Grid>
                    </Container>
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default MusicaScreen;

import React from "react";
import {
    Box,
    makeStyles,
    SvgIcon,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { ReactComponent as Linia } from "../../theme/icons/linia-rev.svg";
import Logo from "../Logo";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        position: "relative",
    },
    linia: {
        position: "absolute",
        top: "25%",
        left: -16,
        zIndex: 0,
    },
    any: {
        position: "absolute",
        top: "20%",
        left: 5,
        zIndex: 0,
        "& .MuiTypography-root": {
            color: "white",
        },
    },
    image: {
        zIndex: 10,
        backgroundColor: theme.palette.background.default,
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        boxShadow: "none",
        fontSize: 15,
        fontFamily: "Varela Round",
    },
}))(Tooltip);

const EntradaDreta = (props) => {
    const classes = useStyles();
    const { any, foto, offset, icon, blanc, top, tooltip } = props;
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <LightTooltip title={tooltip} placement="left">
            <Box
                textAlign="center"
                className={classes.root}
                style={{
                    marginRight: matches ? offset : -90,
                    marginTop: matches ? top : 0,
                }}
            >
                <Box
                    className={classes.image}
                    style={{
                        backgroundColor: !blanc ? "#d9594e" : "transparent",
                    }}
                >
                    {icon ? (
                        <Logo
                            component={icon}
                            height={70}
                            width={70}
                            color="white"
                        />
                    ) : (
                        <img
                            src={`${process.env.PUBLIC_URL}/images/cronologia/${foto}`}
                            alt="imatge"
                        />
                    )}
                </Box>
                {!blanc && (
                    <>
                        <Box className={classes.any}>
                            <Typography style={{ fontSize: matches ? 15 : 10 }}>
                                {any}
                            </Typography>
                        </Box>
                        <Box className={classes.linia}>
                            <SvgIcon
                                style={{ width: 165, height: 35 }}
                                component={Linia}
                                viewBox="0 0 24 5"
                            />
                        </Box>
                    </>
                )}
            </Box>
        </LightTooltip>
    );
};

export default EntradaDreta;

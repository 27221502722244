import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import routes from './routes';
import { useRoutes } from 'react-router-dom';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { AnalyticsProvider, useAnalytics } from 'use-analytics';
function App() {
    const routing = useRoutes(routes());

    const analytics = Analytics({
        app: 'la-patum',
        page: ({ payload }) => {
            console.log(payload);
        },
        plugins: [
            googleAnalytics({
                trackingId: 'G-W7HSSHJL4X',
            }),
        ],
    });

    return (
        <ThemeProvider theme={theme}>
            <AnalyticsProvider instance={analytics}>
                {routing}
            </AnalyticsProvider>
        </ThemeProvider>
    );
}

export default App;

const TRANSLATIONS_ES = {
    que_es: "Qu'est-ce c'est?",

    Inici: 'Accueil',

    Història: 'Histoire',

    Protagonistes: 'Troupes et personnages',

    festa: 'La fête des sens et des sentiments',

    que_es_1:
        'La Patum est la fête la plus importante de Berga. Remontant à plus de 600 ans, elle a lieu tous les ans pendant la semaine de la Fête-Dieu, entre la fin mai et la fin juin, à des dates variables donc, puisque la Fête-Dieu fait partie des fêtes du cycle de Pâques. La danse et le feu en sont les éléments majeurs.',

    que_es_2:
        'Les principales manifestations sont organisées entre le mercredi et le dimanche sur les places et dans les rues de Berga. Le mercredi et le samedi ont lieu des défilés, et le jeudi et le dimanche la Patum de Lluïment (Patum d’apparat, à la-mi-journée) et la Patum complète (le soir) envahissent la Plaça de Sant Pere. Ce sont les comparses (troupes) qui meuvent, interprètent ou font danser les personnages pendant la fête de La Patum.',

    que_es_3:
        'Il y a également des manifestations parallèles en divers endroits de la ville, notamment des activités pour les familles, des concerts destinés aux jeunes et des expositions.',

    vides_de_patum: 'Vies de Patum',

    patum_infantil: 'Patum des enfants',

    Actualitat: 'Événements en cours',

    Notícies: 'Nouvelles',

    Agenda: 'Calendrier',

    Premsa: 'Presse',

    'Totes les notícies': 'Toutes les nouvelles',

    'Dimecres de Corpus': 'Mercredi de la Fête-Dieu',

    'Passacarrers amb salts de Patum i Tirabols':
        'Défilés avec salts de Patum et Tirabols',

    'Dia de Corpus (dijous)': 'Jour de la Fête-Dieu (jeudi)',

    'Patum de Lluïment i Patum completa': 'Patum de Lluïment et Patum complète',

    'Divendres de Corpus': 'Vendredi de la Fête-Dieu',

    'Dissabte de Corpus': 'Samedi de la Fête-Dieu',

    'Diumenge de Corpus': 'Dimanche de la Fête-Dieu ',

    'Saber-ne més': 'Savoir plus',

    diumenge_ascencio: "Dimanche de l'ascension",

    diumenge_ascencio_1:
        "Ple de l'Ascension et roulements de tambour du Tabal annonçant les festivités (17 jours avant le mercredi de la Féte-Dieu).",

    patum_pietat: 'Patum du quartier de la Pietat',

    patum_pietat_1: '12 jours avant le mercredi de la semaine de la Féte-Dieu.',

    quatre_fuets: 'Quatre fuets',

    quatre_fuets_1:
        "Répétition traditionnelle au cours de l'aquelle on passe aun banc d'essai les fusées pyrotechniques (les fuets) qui seront utilisées pendant La Patum (le dimanche précédant la samaine de la Fête-Dieu).",

    patum_llar: 'Patum de la Llar',

    patum_llar_1:
        "Patum organisée à l'école d'enseignement spécial Llar Santa Maria de Queralt (le mardi de la semaine de la Féte-Dieu).",

    que_es_4:
        'Pendant la semaine de la Patum, les défilés passent dans les rues de Berga (voir le plan de cette anée) et les patums ont lieu sur la Plaça de Sant Pere.',

    'Passacarrers migdia dimecres': 'Mercredi midi parcours',

    dimecres_1:
        'Le défilé du mercredi à la mi-journée passe par les principales rues du vieux quartier de Berga. Le Tabaler (joueur de tambour) mène le cortège devant deux couples de Géants au son de la musique de l’orchestre. Suivi par les enfants et les jeunes, c’est le seul défilé de La Patum où il n’y a pas de feu.',

    dimecres_2:
        'Le défilé du mercredi soir parcourt aussi les principales rues du vieux Berga. Il s’arrête en vingt-et-un endroits pour rendre hommage aux élus et autres autorités. Le Tabal (tambourin) défile à l’avant et fait un salt (« saut », ou danse en rond) à chaque fois qu’il s’arrête devant l’endroit choisi par chacun des conseillers municipaux. Le feu (avec les Maces, ou massues, et les Guites, ou mules rueuses) et la danse (avec les Maces et les Géants) sont toujours présents dans les salts. Entre les salts, les comparses avancent en dansant au rythme de la musique.',

    dimecres_3:
        'Le défilé se termine avec les Tirabols sur la Plaça de Sant Joan, où tout le monde fait la ronde autour de la place au son de la musique. Ensuite a lieu la montée de la grand-rue, le Carrer Major, au son de la mélodie populaire Ella s’ho pensa, qui est interprétée sans interruption dans cette rue, et seulement dans cette rue. Et lorsque tout le monde est arrivé sur la Plaça de Sant Pere, les Tirabols reprennent.',

    dimecres_4:
        'Le Tabal, les Maces, les deux Guites et le vieux couple de Géants sont les seules comparses qui participent au défilé.',

    'Patum de lluïment dijous i diumenge al migdia':
        'Patum de Lluïment jeudi et dimanche à la mi-journée',

    dijous_1:
        'La Patum de Lluïment se déroule à la mi-journée. C’est La Patum « de gala », une Patum d’apparat, tranquille, dans une ambiance détendue. À part les Plens (qui n’apparaissent qu’en soirée), toutes les comparses y participent et effectuent leur danse, chacune à son tour. Une fois ces danses terminées commencent les Tirabols, où les deux Guites et les deux couples de Géants évoluent sur la place en même temps, tandis que la foule fait la ronde autour de la place au son de la musique.',

    "Ordre d'aparició de les comparses": "Ordre d'apparition des comparses",

    'Patum completa dijous i diumenge vespre':
        'Patum complète jeudi et dimanche soir',

    dijous_2:
        'La Patum complète est la partie la plus populaire de la fête, celle où il y a le plus de monde. Les comparses dansent au centre de la place, qui est pleine de gens. Les comparses se produisent une par une et dansent au son du Tabal (tambourin) ou de l’orchestre, tandis que les gens font des sauts autour d’elles. Les comparses se produisent à quatre reprises, sauf les Plens, qui n’apparaissent qu’à la fin de la deuxième et de la quatrième danse des autres comparses. Chaque « ronde » est appelée salt et chaque danse des Plens est appelée salt de Plens. Il y a donc deux salts, un salt de Plens, deux salts et un dernier salt de Plens dans une Patum complète. Autrement dit, deux fois les trois mêmes salts.',

    dijous_3:
        'La Patum complète se termine par la danse des Tirabols : les deux Guites et les deux couples de Géants se partagent le cœur de la place, tandis que la foule en fait le tour en ronde au son de la musique.',

    'Escola de Patumaires': "L'école de Patumaires",

    'Visita el Web': 'Rendez-vous sur notre site',

    divendres_1:
        'La Patum des enfants a lieu le vendredi de la semaine de la Fête-Dieu, mais sa préparation comence bien avant, avec des répétitions quotiddienes pendant deux semaines. Les enfants de Berga font leur propre Patum sur la Plaça de Sant Pere avec des personnages adaptés à leur âge.',

    Comparses: 'Troupes et personnages',

    Música: 'Musique',
    'La Música': 'Musique',

    'Administradors/es': 'Administrateurs/trices',

    Patumaires: 'Patumaires',

    'Totes les notícices': 'Toutes les nouvelles',

    'Tots els esdeveniments': 'Tous les évènements',

    Orígens: 'Origines',

    historia_1:
        'Plus de 600 ans d’histoire : de pièce de théâtre médiévale à patrimoine oral et immatériel de l’Humanité ',

    historia_2:
        'L’origine de La Patum remonte aux intermèdes qui accompagnaient les processions de la Fête-Dieu et qui étaient destinés à éduquer, moraliser et édifier le peuple, afin qu’il apprenne la leçon des Saintes Écritures. Au fil du temps, ces intermèdes sont devenus des représentations à part entière et ont conquis le peuple, plus par leur côté ludique que par leur côté moralisateur.\n\nLeur sens primitif s’est peu à peu perdu et il n’en reste plus aujourd’hui que les aspects les plus festifs. L’animation qui entourait les processions a pris de l’éclat, notamment avant le départ des processions et à la fin.\n\nLes intermèdes qui faisaient partie des processions sont devenus des manifestations festives qui ont fini par donner la Bulla ou Bullícia del Santíssim Sagrament (la célébration festive du Saint-Sacrement). Cet ancien nom de la fête a été remplacé par celui de « La Patum » à la fin du XIXe siècle.\n\nCe genre de festivités, qui avaient lieu dans plusieurs villes et bourgades de Catalogne, a peu à peu disparu à cause des interdictions promulguées par les autorités civiles, religieuses et royales, de sorte que peu d’entre elles survécurent au Concile de Trente, en 1563. Puis tous les intermèdes furent frappés d’interdiction par les dispositions restrictives du XVIIIe siècle.\n\nDe toutes les festivités de la Fête-Dieu qui avaient lieu autrefois en Catalogne, seules celles de la ville de Berga ont pu être préservées à travers les siècles. C’est aujourd’hui La Patum.',

    'Cronologia sencera': 'Toute la chronologie',

    unesco_titol: 'Chef-d’œuvre du patrimoine oral et immatériel de l’Humanité',

    unesco: 'Le 25 novembre 2005, un jury international réuni au siège de l’Unesco à Paris rendait publique la liste des formes d’expression culturelle proclamées « chef-d’œuvre du patrimoine oral et immatériel de l’Humanité ». Parmi elles figurait La Patum de Berga, qui, en raison de son originalité et de sa haute valeur artistique et historique, devenait ainsi la première manifestation populaire à obtenir cette distinction en Catalogne et la seconde en Espagne, après le Mystère d’Elche.\n\n L’obtention de cette distinction constitue une raison de plus pour maintenir l’essence de la fête, puisque l’Unesco elle-même est devenue une protectrice directe de cette forme d’expression culturelle.\n\nLa reconnaissance de La Patum comme chef-d’œuvre du patrimoine oral et immatériel de l’Humanité est venue récompenser tous ceux qui ont perpétué la plus vivante de nos traditions et, au-delà, tous ceux qui aiment La Patum et sont fiers de lui redonner vie, année après année, pour la Fête-Dieu.',

    titol_tabal:
        'Le Tabal. C’est lui qui annonce la fête. C’est le roulement de ce tambourin (« pa-tum ! pa-tum… ! ») qui a donné son nom à la fête à la fin du XVIIIe siècle',

    text_tabal:
        'Le Tabal est le seul élément de La Patum qui participe à toutes les manifestations. Sa présence est constante et, d’une certaine manière, on peut dire qu’il préside à la fête, qu’il la dirige.\n\nAttesté en 1621 et reconstruit en 1726, il fut le seul instrument de La Patum au rythme duquel évoluaient les comparses jusqu’au milieu du XVIIe siècle. Le Tabal ne renvoyait à aucune symbolique précise, son rôle était juste de diriger et de coordonner les différents intermèdes et de marquer le rythme de chacune des danses.\n\nLe Tabal joua un rôle moins important à partir de la fin du XIXe siècle, lorsque l’on commença à introduire des morceaux de musique dans la fête.\n\nMais le Tabal et le Tabaler restent néanmoins indispensables à La Patum. Le dimanche de l’Ascension, le Tabal annonce à tout Berga l’arrivée imminente de La Patum. La veille de la Fête-Dieu, à midi, il est en tête du défilé des Géants et, le soir (tout comme le samedi qui suit), il est également en tête des défilés. Actuellement, il préside depuis un endroit privilégié les représentations de La Patum sur la Plaça de Sant Pere. Dans les temps anciens, il était devant toutes les autres comparses de La Patum lors des processions de la Fête-Dieu.\n\nEt s’il arrive qu’une année l’on n’entende pas le roulement du Tabal annonçant la décision municipale le dimanche de l’Ascension, c’est qu’il n’y aura pas de Patum cette année-là !',

    titol_turcs:
        'Les Turcs i Cavallets. La danse des Turcs i Cavallets est sans doute l’une des plus anciennes danses de La Patum',

    text_turcs:
        'Si ces personnages dérivent peut-être d’anciennes représentations liées à l’élevage des troupeaux, ils figurent aujourd’hui la lutte séculaire entre la Croix et le Croissant.\n\nQuatre chevaliers chrétiens et quatre fantassins turcs simulent une bataille dont les premiers sortent toujours victorieux.\n\nLa première mention connue de ces personnages date de 1621, mais les personnages actuels ont été conçus en 1890. Jusqu’en 1889, les cavallets (petits chevaux) de Berga avaient une forme simple, naïve, rudimentaire : la croupe du cheval était simulée par un cerceau recouvert d’un tissu qui cachait les jambes du cavalier ; il avait une petite tête en bois, et une poupée était assise sur sa croupe. Les nouveaux cavallets, commandés à la maison La Perfección, à Barcelone, coûtaient 200 pesetas (1,20 euros). Curieusement, les vêtements des Turcs et des cavallets, qui provenaient de la même maison, coûtaient plus cher que les cavallets, 250 pesetas (1,50 euros).\n\nÀ partir de 1890, Turcs et cavallets n’évolueront plus au rythme du Tabal mais sur les accords d’une musique composée par Joaquim Serra i Farriols, alias Quimserra.',

    titol_maces: 'L’éternelle lutte entre le Bien et le Mal',

    text_maces:
        'Cette représentation, attestée dès 1621, symbolise la lutte éternelle entre le Bien et le Mal, soit entre les anges et les démons, dans une mise en scène naïve.\n\nLes Maces consistent en un manche surmonté d’une boîte ronde contenant des cailloux émettant un son caractéristique lorsque la boîte est remuée. Elles sont ornées de faces diaboliques et sont surmontées d’une sorte de fontaine à étincelles appelée fuet. Elles sont portées par des personnages masqués habillés en diable.\n\nAu cours de la danse, les Maces évoluent sur la place jusqu’à ce que le fuet explose. À cet instant, les diables tombent à terre et les anges les achèvent à la lance et à l’épée.\n\nLa danse des Maces est la dernière à avoir été mise en musique. Mais cette musique, composée par Joan Trullàs en 1963, n’est jouée que lors des salts de Lluïment (à la mi-journée). Le soir, les Maces évoluent comme elles l’ont toujours fait, au son du Tabal\n\nAutrefois, les Maces et les Plens composaient un seul et même tableau, celui des diables.',

    titol_angels: 'Les représentants du Bien luttant contre le Mal',

    text_angels:
        'Les Anges sont indissociables des Maces car ils font partie du même tableau. Actuellement, deux représentants des forces du Bien interviennent dans ce tableau : saint Michel et un autre ange, qui l’accompagne.\n\nLes Anges sont attestés dès 1621, mais il n’y avait alors que saint Michel. L’ange qui l’accompagne ne fera définitivement partie de la représentation qu’à partir de la première moitié du XIXe siècle.\n\nJusqu’à la première décennie du XXe siècle, l’archange saint Michel portait un masque, qui est aujourd’hui conservé au musée cantonal de Berga.',

    titol_guites: 'L’un des éléments les plus primitifs de La Patum',

    text_guites:
        'On les appelait autrefois Mulassa, Mulafera ou Mulaguita. C’est l’un des plus anciens et des plus originaux éléments de La Patum. Tout comme les Plens, ils sont uniques au monde. Ces mules aux formes archaïques ne se retrouvent nulle part dans le bestiaire festif espagnol, et même mondial.\n\nJadis il n’y en avait qu’une, géante, la Guita Grossa, attestée dès 1621.\n\nLes Guites sont l’élément de La Patum qui a le moins changé au fil des siècles, ce qui explique qu’elles présentent des formes plus primitives que les autres. Mais, si elles n’ont pas changé, le nombre de personnes qui les portent, les guitaires, lui, a changé. De deux au début du XIXe siècle, le nombre de leurs porteurs a progressivement augmenté, jusqu’à être de 28 pour la Guita Grossa et de 16 pour la Guita Xica.\n\nLe changement le plus important qu’a connu cette comparsa au long de son histoire a été l’introduction de la Guita Xica, communément appelée Boja (la folle), en 1890, dans une période de revitalisation de La Patum. Elle apparut dans le Carrer de les Canals (aujourd’hui Carrer de Marcel·lí Buxadé) lors de l’une des nombreuses patums de quartier qui avaient lieu à cette époque. Cette année-là, après avoir obtenu de la mairie de Berga l’autorisation nécessaire, elle surgit par surprise sur la place. Elle fit tellement bonne impression qu’elle finit par s’incorporer à La Patum aux côtés de la Guita Grossa.\n\nLes Guites constituent la seule comparsa de La Patum à continuer à « sauter » exclusivement au son du Tabal. Bien que plus de 400 ans aient passé depuis la première nouvelle que nous ayons de la Guita, il est fort possible que son salt n’ait pas changé depuis.',

    titol_aliga:
        'L’Aigle. Il interprète la danse la plus majestueuse et la plus appréciée par les habitants de la ville',

    text_aliga:
        'L’Aigle est sans nul doute l’élément du bestiaire festif catalan qui se différencie le plus des autres. C’est le personnage le plus noble, le plus aristocratique, le plus distingué. Il jouissait autrefois de plusieurs privilèges, notamment danser dans le chœur de l’église ou précéder l’ostensoir lors des processions de la Fête-Dieu.\n\nL’Aigle de La Patum est plus récent que d’autres éléments de la fête, mais ses origines sont encore aujourd’hui une grande énigme.\n\nEn 1756, la mairie de Berga décida d’introduire un aigle dans les cérémonies de la Fête-Dieu et demanda au menuisier Ramon Roca de lui en construire un. C’est peut-être celui-là même, restauré et modifié, qui danse actuellement lors de La Patum.\n\nC’est lui qui exécute la danse la plus chorégraphique de toute la représentation, et sa musique est à la fois la plus distinguée et la plus imposante. Son extraordinaire partition présente de nombreuses ressemblances avec celles des danses de la Renaissance, et elle pourrait donc dater du XVIe siècle ; mais, d’un autre côté, elle a des accents mélodiques qui pourraient provenir d’un hymne grégorien. Sa mélodie est tellement exceptionnelle qu’elle a pu être décrite comme l’un des trois morceaux les plus « géniaux » de la musique populaire catalane.',

    'titol_nans-vells':
        'Quatre personnages portant tricorne et jouant des castagnettes',

    'text_nans-vells':
        'Les Géants et les Nains sont les éléments festifs les plus populaires depuis des siècles. Pratiquement toutes les villes et tous les bourgs de Catalogne ont les leurs. Et, bien entendu, Berga ne fait pas exception.\n\nL’arrivée des Vieux Nains et des Nouveaux Nains dans La Patum est relativement récente. Les Vieux Nains datent de 1853, année où Ferran Moragues i Ubach, premier député du district de Berga au Parlement, les offrit à la ville à un moment où ce genre de personnages était très en vogue.\n\nIl s’agit de quatre personnages masculins, portant tricorne et longue perruque, qui dansent et jouent des castagnettes au son de mélodies populaires, les mêmes que celles sur lesquelles dansent les Géants. Le fait qu’ils ne disposent pas de leur propre musique semble pouvoir s’expliquer par leur arrivée par surprise à Berga, qui les amena à danser au rythme de mélodies déjà interprétées à Berga',

    titol_gegants: 'Les anciens chefs musulmans vaincus',

    text_gegants:
        'Le premier Géant de la fête apparaît, au moins, en 1622 et le premier couple en 1695. La tradition populaire voit en eux, surtout depuis l’époque romantique, la représentation d’anciens chefs musulmans vaincus.\n\nActuellement, deux couples de géants dansent ensemble lors de La Patum : les Vieux Géants, apparus en 1866 à la place d’autres plus anciens, et les Nouveaux Géants, construits en 1891.\n\nIls dansent la plupart du temps sur des adaptations de mélodies populaires catalanes, mais il leur arrive aussi de danser sur des mélodies composées spécialement pour eux par deux habitants de Berga, Jaume Sala et le père Marià Miró',

    'titol_nans-nous': 'Quatre personnages remuants et difformes',

    'text_nans-nous':
        'Apparus en 1890, ils devaient remplacer les Vieux Nains, alors en très mauvais état. Par chance, ces derniers furent restaurés et ont toujours toute leur place lors de chaque Patum.\n\nLes Nouveaux Nains sont deux couples, l’un jeune et l’autre vieux, qui dansent au son d’une mélodie légère et enjouée composée à la fin du XIXe siècle par Joaquim Serra i Farriols, dit Quimserra. Cette mélodie est la plus connue et la plus appréciée de toutes celles de La Patum, et elle figure au répertoire de nombreux orchestres populaires catalans sous le titre de La Patum de Berga. Auparavant ils avaient dansé sur des airs en vogue à l’époque, comme le rigaudon ou des mélodies venues des Amériques.',

    titol_plens: 'L’apothéose de la fête',

    text_plens:
        'C’est le plus grand spectacle de La Patum, son point culminant, l’apothéose de la fête.\n\nIls existent depuis, au moins, l’année 1621et l’on pense qu’il doivent leur nom au fait qu’ils sont « pleins » (plens en catalan) de feu. Il s’agit donc de diables pleins de feu.\n\nJusqu’à la fin du XIXe siècle, les Plens ne dansaient qu’au son du Tabal, mais, depuis, ils le font au rythme de la géniale musique endiablée composée par Quimserra. Lorsque les lumières s’éteignent et que commence la musique, la place se transforme en un enfer rempli du feu lancé par mille fuets se consumant en même temps. Il y a, en tout, une centaine de Plens portant chacun neuf fuets. Tous sont accompagnés par quelqu’un qui les guide à travers la place pendant toute leur prestation.\n\nLe nombre des Plens a fortement augmenté au fil du temps. Au XVIIe siècle il n’y avait que deux diables. Avant 1936, il y en avait quatre, huit ou, exceptionnellement, jusqu’à douze selon les années. Lorsque, en 1916, à l’occasion de La Patum extraordinaire qui eut lieu pour le couronnement canonique de Notre-Dame de Queralt, apparurent seize Plens, les gens dirent qu’il y avait trop de feu sur la place et que c’était trop dangereux. Mais plus tard leur nombre augmenta jusqu’à quarante et, aujourd’hui, il y en a une centaine.',

    'El component fonamental': 'Un élément essentiel',

    musica_1:
        'La musique est un élément essentiel de la fête, surtout depuis la fin du XIXe siècle, lorsque différentes mélodies furent incorporées à La Patum. On ne conçoit plus aujourd’hui La Patum sans les musiques interprétées par la Cobla Pirineu, la Cobla Ciutat de Berga et la Banda de l’Escola Municipal de Música de Berga.\n\nIl s’agit surtout de musique populaire, simple mais sous certains aspects unique et géniale. Plusieurs morceaux sont dus à Joaquim Serra i Farriols, dit Quimserra (Berga, 1834-1906), qui composa ceux des Turcs i Cavallets, des Plens et des Nouveaux Nains. Quimserra changea radicalement le paysage musical de La Patum, en le modernisant définitivement.\n\nIl nous faut également mentionner Ricard Cuadra i Camprubí (Berga, 1951-1997), qui organisa le « concert de musique oubliée de La Patum » en 1993 pour faire revivre des morceaux que l’on n’interprétait plus depuis des décennies. Ce fut un tel succès que l’initiative fut reprise en 1995 et 1997, avec d’autres morceaux jusqu’alors oubliés. Après la mort prématurée de Ricard Cuadra, en 1997, fut créé le Mémorial Ricard Cuadra, qui poursuit l’œuvre qu’il laissa inachevée en organisant un concert tous les ans, le samedi de la Sainte-Trinité.',

    administradors_1:
        'Ils étaient autrefois chargés de récolter des fonds pour régler les dépenses de la fête',

    administradors_2:
        'Les Administrateurs et les Administratrices ne constituent pas vraiment une comparse de La Patum, mais leur rôle n’en est pas moins important.\n\nDes documents attestent de leur existence dès 1619. Leur travail était de récolter des fonds pour payer les festivités de l’Octave de la Fête-Dieu.\n\nIl s’agit de quatre couples représentant les quatre quartiers qui composaient autrefois la ville : Feixines ou Capdamunt de la Vila (Fonolls), Sant Pere (Esclafidors rebentats), Centre ou Carrer Major (Esclafidors embotits) et Raval (Ravenisses). Leur rôle est maintenant protocolaire, puisqu’ils président les fêtes depuis le balcon de l’Hôtel de Ville.',

    patum_infantil_1:
        'La Patum des enfants a lieu le vendredi de la Fête-Dieu, mais les enfanPprts la préparent bien avant. Les répétitions durent deux semaines, au cours desquelles les enfants de la ville apprennent les différentes danses de la fête. C’est la raison pour laquelle on appelle la Patum des enfants « l’école de patumaires ».\n\nLe vendredi de la Fête-Dieu, les enfants de Berga représentent leur propre Patum sur la Plaça de Sant Pere. On y retrouve les mêmes éléments que dans celle des adultes, mais avec des dimensions moindres.\n\nLe matin, La Patum des enfants commence par un défilé dans les rues de Berga. À la mi-journée et dans l’après-midi ont lieu des représentations de La Patum de Lluïment et de La Patum complète avec toutes leurs comparses : Tabal, Turcs i Cavallets, Maces i Àngels (Anges), Guites, Aigle, Vieux Nains, Géants, Nouveaux Nains, Plens et Tirabols.',

    'Per a més informació:': 'Pour en savoir plus :',

    patumaires_1:
        'La Patum est l’œuvre de milliers et de milliers de patumaires. Et, chaque année, la Ville de Berga distingue un certain nombre d’entre eux qui, par leur dévouement et leur engagement au cours de la fête, ont mérité d’être élevés au rang de Patumaire ou de Patumaire d’honneur.',
    'Títol de Patumaire': 'Título de Patumaire',
    "Patumaire d'honor": 'Patumaire de honor',
    'Objecte i composició': 'Objet et composition',
    text_objecte:
        'Le Conseil d’administration communal de La Patum est un organe de participation sectoriel depuis le 29 mars 2017. Il a pour mission d’administrer, conserver et coordonner le déroulement de la fête de La Patum, déclarée chef-d’œuvre du patrimoine oral et immatériel de l’Humanité par l’Unesco. Ses compétences s’étendent à toutes les activités liées aux comparses de la fête, à leurs spectacles et à leurs membres. Il veille également à la bonne utilisation du nom et de l’image de la fête ; il protège son image de marque et veille à ce qui est en lien avec des contenus publicitaires, de communication, etc., incluant, de façon générale ou spécifique, le nom « Patum » ou y renvoyant. Il a été constitué le 24 avril 2001 sous forme d’organisme autonome municipal.\n\nLe Conseil d’administration communal de La Patum est composé de membres des différents groupes politiques représentés au conseil municipal, de plusieurs chefs d’équipe (par roulement) et de représentants des patumaires. Sa composition est renouvelée tous les deux ans. Voici sa composition actuelle (2019-2021) : ',
    Normativa: 'Règlementation',
    text_normativa:
        'Le Conseil d’administration communal de La Patum dispose d’une règlementation régissant sa mission, sa composition et son fonctionnement en tant qu’organe de participation sectoriel.',
    'Acords de les sessions': 'Accords de session',
    text_acords:
        'Le Conseil d’administration communal de La Patum se réunit régulièrement pendant toute l’année en séance ordinaire ou extraordinaire pour aborder toutes sortes de questions en lien avec la fête.',
    'Reglaments de règim intern de les comparses':
        'Règlement intérieur des comparses',
    text_reglaments:
        'Depuis 2017, les comparses de La Patum disposent d’un règlement intérieur précisant notamment sa composition, son fonctionnement et les mécanismes dont dispose la population pour en faire partie ou participer aux répétitions et aux salts. Ce règlement a été ratifié par le Conseil d’administration communal de La Patum.',
    Formularis: 'Formes',
    'Formularis de sol·licitud': 'Formulaires de demande',
    text_formularis:
        'La mairie de Berga et le Conseil d’administration communal de La Patum encouragent la participation de tous dans différents domaines : lors du concours d’affiches de La Patum, lors du choix des Administrateurs/trices, lors du tirage au sort pour l’accès au balcon pendant les patums sur la place ou lors du concours pour l’ouverture de buvettes à l’occasion du concert destiné aux jeunes, entre autres.',
    glossari_comparsa:
        'C’est le nom donné aux troupes en charge de mouvoir, interpréter ou faire danser les personnages : le Tabal, les Turcs i Cavallets, les Anges, les Maces, la Guita Grossa, la Guita Xica, l’Aigle, les Vieux Nains, les Géants, les Nouveaux Nains et les Plens.',
    glossari_passacarrers:
        'Défilés passant par les places et les rues de Berga, auxquels participent plusieurs comparses de La Patum. Les comparses s’arrêtent à des endroits bien définis, où elles font des salts. Il y a trois défilés (avec des parcours différents) pendant la semaine de la Fête-Dieu : le mercredi à la mi-journée et le soir, et le samedi soir.',
    glossari_salt:
        'Un salt peut aussi bien désigner une performance ou une danse effectuée par une comparsa donnée qu’une « ronde » de performances ou de danses réalisée par plusieurs comparses. Lors des défilés, la ronde n’est effectuée que par les Maces, les Guites et les Géants, tandis que toutes les comparses participent aux patums sur la place, où la ronde commence avec les Turcs i Cavallets pour se terminer avec les Nouveaux Nains.',
    glossari_plens:
        'Lors d’un salt de Plens, la Plaça de Sant Pere est remplie par une centaine de plens (démons « pleins » de feu), auxquels s’ajoutent leurs accompagnateurs et tous ceux qui veulent y prendre part. Lorsque les lumières de la place s’éteignent et que la musique commence, les accompagnateurs allument avec leurs feux de Bengale les neuf fuets dont est muni chacun des démons. Commence alors une danse autour de la place dans le sens contraire à celui des aiguilles d’une montre. En cas d’incident, la musique s’arrête et on rallume les réverbères. La danse se termine dès que tous les fuets ont explosé.',
    glossari_fuet:
        'Nom donné au matériel pyrotechnique utilisé par les comparses jouant avec le feu (Maces, Guites et Plens). Le fuet est composé de deux parties : l’une est allumée et brûle en projetant des étincelles pendant plus d’une minute ; l’autre explose lorsque tout le fuet est consumé.',
    glossari_tirabol:
        'Danse qui met un point final aux défilés et aux patums sur la place. Pendant le Tirabol, les Géants et les Guites dansent ensemble au son du Tabal (tambourin) et de la musique, tandis que la foule fait la ronde autour de la place.',
    glossari_barreja:
        'Boissons alcooliques associées depuis toujours à La Patum. La barreja est un mélange d’anis et de vin de muscat et le maumau est un mélange de vermouth rouge et de limonade. Les gens du cru les boivent à la gourde ou au porró (pichet à goulot long et étroit).',
    Càrrec: 'Positionner',
    'Nom i cognoms del o la titular': 'Nom et prénom du propriétaire',
    'Nom i cognoms del o la suplent': 'Nom et prénom du suppléant',
    Glossari: 'Glossaire',
    Consells: 'Conseils',
    'Vides de Patum': 'Vies de Patum',
    vides: 'À la suite de la proclamation de La Patum comme chef-d’œuvre du patrimoine oral et immatériel de l’Humanité, la Ville de Berga a lancé, en 2016, sous le titre « Vies de Patum », une initiative consistant à enregistrer des entretiens avec des personnes ayant activement participé la fête, de façon à recueillir leurs témoignages.',
    'Dossiers de premsa': 'Dossiers de premsa',
    Acreditacions: 'Accréditations',
    Contacte: 'Contact',
    'Correu electrònic': 'E-mail',
    'Correu electrònic premsa': 'E-mail presse',
    Telèfon: 'Téléphone',
    'Disseny i programació': 'Conception et programmation',
};

export default TRANSLATIONS_ES;

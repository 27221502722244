import React from 'react';
import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import Entrada from './Entrada';
import EntradaDreta from './EntradaDreta';
import { ReactComponent as Admin } from '../../theme/icons/administradors.svg';
import { ReactComponent as Tabal } from '../../theme/icons/tabal-sol.svg';
import { ReactComponent as Angels } from '../../theme/icons/angels-sol.svg';
import { ReactComponent as Turcs } from '../../theme/icons/turcs-sol.svg';
import { ReactComponent as Guites } from '../../theme/icons/guites-sol.svg';
import { ReactComponent as GuitaXica } from '../../theme/icons/guita-xica.svg';
import { ReactComponent as Maces } from '../../theme/icons/maces-sol.svg';
import { ReactComponent as Plens } from '../../theme/icons/plens-sol.svg';
import { ReactComponent as Gegants } from '../../theme/icons/gegants-sol.svg';
import { ReactComponent as Aliga } from '../../theme/icons/aliga-sol.svg';
import { ReactComponent as Bulla } from '../../theme/icons/bulla.svg';
import { ReactComponent as NansVells } from '../../theme/icons/nans-vells-sol.svg';
import { ReactComponent as NansNous } from '../../theme/icons/nans-nous-sol.svg';
import { ReactComponent as PatumInfantil } from '../../theme/icons/patum-infantil.svg';
import { ReactComponent as Musica } from '../../theme/icons/musics.svg';
import { ReactComponent as Potencia } from '../../theme/icons/potencia.svg';
import { ReactComponent as Unesco } from '../../theme/icons/unesco.svg';
import { ReactComponent as Covid } from '../../theme/icons/covid.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    col2: {
        backgroundColor: 'white',
        width: 20,
    },
    col1: {
        width: '50%',
    },
    col3: {
        width: '50%',
    },
    colText1: {
        color: 'white',
        textAlign: 'right',
        marginRight: 20,
        marginBottom: 10,
    },
    colText2: {
        color: 'white',
        textAlign: 'left',
        marginLeft: 20,
        marginBottom: 10,
    },
}));

const tooltip = {
    tabal: 'EL TABAL: Primer document on hi consta aquesta comparsa de La Patum',
    angels: 'ELS ÀNGELS: Primer document on hi consta aquesta comparsa de La Patum',
    turcs: 'ELS TURCS I CAVALLETS: Primer document on hi consta aquesta comparsa de La Patum',
    guita: 'LA GUITA: Primer document on hi consta aquesta comparsa de La Patum',
    maces: 'LES MACES: Primer document on hi consta aquesta comparsa de La Patum',
    plens: 'ELS PLENS: Primer document on hi consta aquesta comparsa de La Patum',
    gegants:
        'ELS GEGANTS: Un Gegant és citat per primera vegada en la documentació',
    aliga: "L'ÀLIGA: Construcció d'una àliga",
    nansVells: "ELS NANS VELLS: Els Nans s'incorporen a la festa",
    guitaXica: "LA GUITA XICA: La Guita Xica s'incorpora a la festa",
    nansNous:
        'ELS NANS NOUS: Van ser estrenats per tal que subtituïssin els Nans Vells',
    musica: "ELS MÚSICS: A finals de segle, la música s'incorpora a La Patum",
    corpus: 'Institució de la Festa del Corpus Christi',
    celebracio: 'Primera notícia coneguda de la celebració del Corpus a Berga',
    administradors:
        'ADMINISTRADORS: Es té constància documental dels primers Administradors de la festa',
    guerra: 'El final de la Guerra de Successió marca la no celebració de La Patum durant aquests anys',
    bulla: 'La Bulla canvia el seu nom pel de Patum',
    foto: 'Primera fotografía coneguda de La Patum',
    potencia: 'Procés de potenciació de La Patum',
    patumInfantil: 'Aparició de La Patum infantil',
    unesco: 'La UNESCO declara La Patum Obra Mestra del Patrimoni Oral i Immaterial de la Humanitat',
    covid: 'Suspensió de La Patum per causa de la crisi sanitària originada per la pandèmia de la Covid-19.',
};

const Cronologia = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <Box textAlign="center" py={3} className={classes.root}>
            <Box className={classes.col1}>
                <Typography variant="h3" className={classes.colText1}>
                    Corpus
                </Typography>
                <Entrada
                    any="1262"
                    foto="corpus.png"
                    offset={-40}
                    tooltip={tooltip.corpus}
                />
                <Entrada
                    any="1454"
                    foto="celebracio.png"
                    offset={40}
                    tooltip={tooltip.celebracio}
                />
                <Entrada
                    any="1619"
                    icon={Admin}
                    offset={-60}
                    tooltip={tooltip.administradors}
                />
                <Box my={matches ? 25 : 45} />
                <Entrada
                    any="1714-1720"
                    foto="guerra.png"
                    offset={-60}
                    tooltip={tooltip.guerra}
                />
                <Box my={matches ? 4 : 8} />
                <Entrada
                    any="1795-1809"
                    icon={Bulla}
                    offset={-60}
                    tooltip={tooltip.bulla}
                />
                <Entrada
                    any="1868"
                    foto="foto.png"
                    offset={-60}
                    tooltip={tooltip.foto}
                />
                <Box my={matches ? 3 : 7} />
                <Entrada
                    any="1888-1892"
                    icon={Potencia}
                    offset={-60}
                    tooltip={tooltip.potencia}
                />
                <Box my={matches ? 5 : 12} />
                <Entrada
                    any="1956"
                    icon={PatumInfantil}
                    offset={40}
                    tooltip={tooltip.patumInfantil}
                />
                <Entrada
                    any="2005"
                    icon={Unesco}
                    offset={-60}
                    tooltip={tooltip.unesco}
                />
            </Box>
            <Box className={classes.col2}></Box>
            <Box className={classes.col3}>
                <Typography variant="h3" className={classes.colText2}>
                    Comparses
                </Typography>
                <Box my={30} />
                <EntradaDreta
                    any="1621"
                    icon={Tabal}
                    offset={50}
                    tooltip={tooltip.tabal}
                />
                <EntradaDreta
                    icon={Angels}
                    offset={-90}
                    top={-50}
                    tooltip={tooltip.angels}
                    blanc
                />
                <EntradaDreta
                    icon={Turcs}
                    offset={50}
                    top={-20}
                    tooltip={tooltip.turcs}
                    blanc
                />
                <EntradaDreta
                    icon={Guites}
                    offset={-90}
                    top={-40}
                    tooltip={tooltip.guita}
                    blanc
                />
                <EntradaDreta
                    icon={Maces}
                    offset={50}
                    top={-30}
                    tooltip={tooltip.maces}
                    blanc
                />
                <EntradaDreta
                    any="1622"
                    icon={Plens}
                    offset={-60}
                    tooltip={tooltip.plens}
                    top={-10}
                />
                <EntradaDreta
                    icon={Gegants}
                    offset={50}
                    top={-30}
                    tooltip={tooltip.gegants}
                    blanc
                />
                <EntradaDreta
                    any="1756"
                    icon={Aliga}
                    offset={-40}
                    tooltip={tooltip.aliga}
                    top={-10}
                />
                <EntradaDreta
                    any="1853"
                    icon={NansVells}
                    offset={40}
                    tooltip={tooltip.nansVells}
                    top={-10}
                />
                <Box my={17} />
                <EntradaDreta
                    any="1890"
                    icon={GuitaXica}
                    offset={-60}
                    tooltip={tooltip.guitaXica}
                    top={-5}
                />
                <EntradaDreta
                    icon={NansNous}
                    offset={60}
                    top={-30}
                    tooltip={tooltip.nansNous}
                    blanc
                />
                <EntradaDreta
                    icon={Musica}
                    offset={-50}
                    top={-30}
                    tooltip={tooltip.musica}
                    any="s.XIX"
                />
                <Box my={14} />
                <EntradaDreta
                    icon={Covid}
                    offset={-50}
                    top={-30}
                    tooltip={tooltip.covid}
                    any="2020-2021"
                />
            </Box>
        </Box>
    );
};

export default Cronologia;

import {
    Box,
    Container,
    Divider,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import { ReactComponent as Masses } from "../../theme/icons/masses.svg";
import { ReactComponent as Gegants } from "../../theme/icons/gegants.svg";
import { ReactComponent as Guites } from "../../theme/icons/guites.svg";
import { ReactComponent as Tabal } from "../../theme/icons/tabal.svg";
import Logo from "../../components/Logo";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
        zIndex: 10,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    mainM: {
        paddingTop: 10,
        paddingBottom: 200,
    },
    wrap: {
        paddingRight: 40,
    },
    wrap2: {
        paddingLeft: 40,
    },
    image: {
        width: "100%",
        position: "relative",
    },
    line: {
        width: 3,
        position: "fixed",
        top: 0,
        backgroundColor: theme.palette.background.default,
        right: "50%",
        height: "100%",
        zIndex: 0,
    },
}));
const Dimecres = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:960px)");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title={t("Dimecres de Corpus")} description={t("dimecres_1")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Divider
                            orientation="vertical"
                            flexItem
                            className={matches && classes.line}
                        />
                        <Header title={t("Dimecres de Corpus")} background />
                        <Grid container>
                            <Grid
                                item
                                md={6}
                                className={
                                    matches ? classes.wrap : classes.wrapMobile
                                }
                            >
                                <Typography variant="h4">
                                    {t("Passacarrers migdia dimecres")}
                                </Typography>
                                <Box p={1}></Box>

                                <Typography variant="body1">
                                    {t("dimecres_1")}
                                </Typography>
                            </Grid>
                            <Grid item md={6}></Grid>
                        </Grid>
                        <Grid container>
                            <Box py={8} style={{ zIndex: 10 }}>
                                <img
                                    alt={t("Dimecres de Corpus")}
                                    src={`${process.env.PUBLIC_URL}/images/dimecres_1.jpg`}
                                    className={classes.image}
                                />
                            </Box>
                        </Grid>
                        <Grid container>
                            <Grid item md={6}></Grid>
                            <Grid
                                item
                                md={6}
                                className={
                                    matches ? classes.wrap2 : classes.wrapMobile
                                }
                            >
                                <Typography variant="h4">
                                    {t(
                                        "Passacarrers amb salts de Patum i Tirabols"
                                    )}
                                </Typography>
                                <Box p={1}></Box>
                                <Typography variant="body1">
                                    {t("dimecres_2")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Box py={8} style={{ zIndex: 10 }}>
                                <img
                                    alt="Dimecres 1"
                                    src={`${process.env.PUBLIC_URL}/images/dimecres_2.jpg`}
                                    className={classes.image}
                                />
                            </Box>
                        </Grid>
                        <Grid container>
                            <Grid
                                item
                                md={6}
                                className={
                                    matches ? classes.wrap : classes.wrapMobile
                                }
                            >
                                <Typography variant="body1">
                                    {t("dimecres_3")}
                                </Typography>
                                <Box p={2}></Box>
                                <Typography variant="body1">
                                    {t("dimecres_4")}
                                </Typography>
                                <Box p={2}></Box>
                                <Box>
                                    <Grid
                                        container
                                        spacing={4}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <Grid item xs={12} md>
                                            <Logo
                                                width={"100%"}
                                                height={170}
                                                icon={Tabal}
                                                color={"#d9594e"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md>
                                            <Logo
                                                width={"100%"}
                                                height={170}
                                                icon={Masses}
                                                color={"#d9594e"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md>
                                            <Logo
                                                width={"100%"}
                                                height={170}
                                                icon={Guites}
                                                color={"#d9594e"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md>
                                            <Logo
                                                width={"100%"}
                                                height={170}
                                                icon={Gegants}
                                                color={"#d9594e"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={6}></Grid>
                        </Grid>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default Dimecres;

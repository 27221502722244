import React from "react";
import { withStyles } from "@material-ui/styles";
import {
    Box,
    Button,
    makeStyles,
    SvgIcon,
    Typography,
} from "@material-ui/core";
import { ReactComponent as Spoti } from "../theme/icons/spotify.svg";

const BootstrapButton = withStyles(
    (theme) => ({
        root: {
            boxShadow: "none",
            textTransform: "none",
            fontSize: 16,
            borderRadius: 30,
            border: "2px solid white",
            lineHeight: 1.5,
            backgroundColor: "#1DB954",
            transition: "0.5s",
            "& .MuiTypography-h4": {
                color: "white",
            },
            padding: 10,
            "&:hover": {
                color: theme.palette.background.default,
                backgroundColor: "#191414",
                boxShadow: "none",
                "& .MuiTypography-h3": {
                    color: "white",
                },
                "& .MuiTypography-body1": {
                    color: "white",
                },
            },
            "&:active": {
                boxShadow: "none",
                backgroundColor: theme.palette.background.default,
                borderColor: theme.palette.background.default,
            },
            "&:focus": {
                boxShadow: "0 0 0 0.1rem ",
            },
        },
        label: {
            color: "white",
        },
    }),
    { withTheme: true }
)(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingLeft: 30,
        paddingRight: 30,
    },
    button: {
        display: "flex",
        alignItems: "center",
    },
}));

export default function Spotify(props) {
    const classes = useStyles();

    return (
        <BootstrapButton
            variant="contained"
            color="primary"
            disableRipple
            className={classes.margin}
            href="https://open.spotify.com/album/6lVuTJeOOWGAnjxib4TaSI?si=fmOR8Ey5R2i-GTqs7AJWIw&dl_branch=1"
            target="_blank"
        >
            <Box className={classes.button}>
                <SvgIcon
                    className={classes.icon}
                    style={{ width: 40, height: 40, marginRight: 10 }}
                    component={Spoti}
                />
                <Typography variant="h4">Spotify</Typography>
            </Box>
        </BootstrapButton>
    );
}

import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import Divide from "../../components/Divide";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { Element } from "react-scroll";
import { getVides } from "../../database/API";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    main2: {
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
}));

const Vides = () => {
    const classes = useStyles();
    const [vides, setVides] = useState([]);
    const { t } = useTranslation();

    const [{ opacity }, set] = useSpring(() => ({
        opacity: 1,
        onRest: () => {
            setTimeout(set.start({ opacity: 1 }), 2000);
        },
    }));

    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { vides } = await getVides();
            console.log(vides);
            setVides(vides);
        };
        get();
    }, []);

    return (
        <Page title={t("Vides de Patum")} description={t("vides")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t("Vides de Patum")} />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation animateIn="fadeIn">
                    <Container className={classes.root}>
                        <animated.div style={{ opacity }}>
                            <Box mb={10}>
                                <Typography variant="body1">
                                    {t("vides")}
                                </Typography>
                            </Box>
                            <Grid container spacing={4}>
                                {vides?.map((item) => {
                                    return (
                                        <Grid item md={4}>
                                            <iframe
                                                width="100%"
                                                height="300"
                                                src={`https://www.youtube.com/embed/${item.video}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title="Embedded youtube"
                                            />
                                            <Box mt={2}>
                                                <Typography variant="h4">
                                                    {item.nom}
                                                </Typography>
                                                <Divide color />
                                                <Typography variant="body1">
                                                    {item.descripcio}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                                <Box py={3} />
                            </Grid>
                        </animated.div>
                    </Container>
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default Vides;

import {
    Avatar,
    Box,
    Container,
    Fade,
    Grid,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Page from "../components/Page";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { getFormularis, getPremsa, publicUrl } from "../database/API";
import { GetApp } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
        paddingBottom: 200,
    },
    header: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        backgroundColor: theme.palette.background.default,
    },
    dossierWrap: {
        padding: 20,
        display: "flex",
        borderRadius: 30,
        justifyContent: "space-between",
        alignItems: "center",
        transition: "0.5s",
        border: "2px solid white",
        "& .MuiTypography-h3": {
            color: "white",
        },
        "& .MuiSvgIcon-root": {
            color: "white",
        },
        "&:hover": {
            color: theme.palette.background.default,
            backgroundColor: "white",
            boxShadow: "none",
            "& .MuiTypography-h3": {
                color: theme.palette.background.default,
            },
            "& .MuiSvgIcon-root": {
                color: theme.palette.background.default,
            },
        },
    },
    nomWrap: {
        alignItems: "center",
        display: "flex",
    },
    abbr: {
        backgroundColor: "white",
        marginRight: 20,
    },
}));

const StyledListItem = withStyles((theme) => ({
    root: {
        backgroundColor: "white",
        fontFamily: "Varela Round",
        textTransform: "uppercase",
        color: theme.palette.text.primary,
        borderBottom: "2px solid #d9594e",
        "&:nth-of-type(1)": {
            borderTop: "2px solid #d9594e",
        },
    },

    body: {
        fontFamily: "Varela Round",
        color: theme.palette.text.secondary,
        borderBottom: "1px solid #d9594e",
        fontSize: 14,
    },
}))(ListItem);

const Formularis = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [formularis, setFormularis] = useState();
    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { data } = await getFormularis();
            setFormularis(data);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Page title={t("Formularis de sol·licitud")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.header}>
                        <Box>
                            <Header title={t("Formularis de sol·licitud")} />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <ScrollAnimation animateIn="fadeIn">
                <Container className={classes.root}>
                    <Box my={4}>
                        <Typography>{t("text_formularis")}</Typography>
                    </Box>
                    {!loading ? (
                        <Fade in={!loading}>
                            <div>
                                {formularis?.map((item) => (
                                    <StyledListItem
                                        button
                                        component="a"
                                        href={`${publicUrl}imatges/${item.pdf}`}
                                        target="_blank"
                                    >
                                        <ListItemIcon>
                                            <GetApp />
                                        </ListItemIcon>
                                        <ListItemText primary={item.nom} />
                                    </StyledListItem>
                                ))}
                            </div>
                        </Fade>
                    ) : (
                        <Loading />
                    )}
                </Container>
            </ScrollAnimation>
            <Footer />
        </Page>
    );
};

export default Formularis;

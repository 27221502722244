import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import {
    Box,
    Fade,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { getReglaments, publicUrl } from "../../database/API";
import moment from "moment/min/moment-with-locales";
import Loading from "../../components/Loading";

const StyledListItem = withStyles((theme) => ({
    root: {
        backgroundColor: "white",
        fontFamily: "Varela Round",
        textTransform: "uppercase",
        color: theme.palette.text.primary,
        borderBottom: "2px solid #d9594e",
        "&:nth-of-type(1)": {
            borderTop: "2px solid #d9594e",
        },
    },

    body: {
        fontFamily: "Varela Round",
        color: theme.palette.text.secondary,
        borderBottom: "1px solid #d9594e",
        fontSize: 14,
    },
}))(ListItem);

export default function Reglaments() {
    const [reglaments, setReglaments] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const get = async () => {
            const { data } = await getReglaments();
            setReglaments(data);
            setLoading(false);
        };
        get();
    }, []);

    return (
        <Box>
            <List component="nav" aria-label="main mailbox folders">
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            {reglaments?.map((item) => (
                                <StyledListItem
                                    button
                                    component="a"
                                    href={`${publicUrl}imatges/${item.pdf}`}
                                    target="_blank"
                                >
                                    <ListItemIcon>
                                        <GetApp />
                                    </ListItemIcon>
                                    <ListItemText primary={item.nom} />
                                </StyledListItem>
                            ))}
                        </div>
                    </Fade>
                ) : (
                    <Loading />
                )}
            </List>
        </Box>
    );
}

import React, { useEffect } from "react";
import {
    Box,
    Container,
    Grid,
    Link,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import i18n from "../../lang";
import Footer from "../../components/Footer";
import Page from "../../components/Page";
import { useLocation, useNavigate } from "react-router";
import Logo from "../../components/Logo";
import { ReactComponent as Lloc } from "../../theme/icons/lloc.svg";
import { ReactComponent as Calendari } from "../../theme/icons/calendari.svg";
import { ReactComponent as Temps } from "../../theme/icons/temps.svg";
import { ArrowBack } from "@material-ui/icons";
import { publicUrl } from "../../database/API";
import MyButtonMini from "../../components/MyButtonMini";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 200,
        backgroundColor: theme.palette.background.default,
    },
    image: {
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: 40,
        marginBottom: 20,
        opacity: 0.75,
    },
    titol: {
        color: "white",
        fontSize: 45,
    },
    titolM: {
        color: "white",
        fontSize: 30,
    },
    cuadres: {
        border: "2px solid white",
        borderRadius: 20,
        padding: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const SingleAgenda = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { state } = useLocation();
    const navigate = useNavigate();
    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log(state);
    });

    return (
        <Page title={state.titol}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Link
                                    onClick={() =>
                                        navigate("/actualitat/agenda/")
                                    }
                                    style={{
                                        cursor: "pointer",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <ArrowBack
                                        style={{
                                            fontSize: 12,
                                            marginTop: 3,
                                            color: "white",
                                        }}
                                    />
                                    <Typography
                                        variant="caption"
                                        style={{ marginTop: 5, color: "white" }}
                                    >
                                        Tornar
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    className={
                                        matches ? classes.titol : classes.titolM
                                    }
                                >
                                    {state.titol}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box my={4} />

                        <Grid container spacing={5}>
                            <Grid item md={4} style={{ width: "100%" }}>
                                <Box className={classes.cuadres}>
                                    <Logo
                                        width={50}
                                        color="white"
                                        icon={Calendari}
                                    />
                                    <Box my={2} />
                                    <Typography
                                        variant="body1"
                                        style={{ color: "white" }}
                                    >
                                        {i18n.moment(state.data).format("dd")}
                                        {", "}
                                        {i18n.moment(state.data).format("LL")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} style={{ width: "100%" }}>
                                <Box className={classes.cuadres}>
                                    <Logo
                                        width={50}
                                        color="white"
                                        icon={Temps}
                                    />
                                    <Box my={2} />

                                    <Typography
                                        variant="body1"
                                        style={{ color: "white" }}
                                    >
                                        {i18n
                                            .moment(state.hora, "HH:mm:ss")
                                            .format("HH:mm")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} style={{ width: "100%" }}>
                                <Box className={classes.cuadres}>
                                    <Logo
                                        width={50}
                                        color="white"
                                        icon={Lloc}
                                    />
                                    <Box my={2} />

                                    <Typography
                                        variant="body1"
                                        style={{ color: "white" }}
                                    >
                                        {state.lloc}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box my={8} />

                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "white",
                                        whiteSpace: "pre-line",
                                    }}
                                >
                                    {state.descripcio}
                                </Typography>
                                {state?.link && state?.nom_link && (
                                    <MyButtonMini
                                        title={state.nom_link}
                                        href={state.link}
                                        target="_blank"
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Box py={10}>
                            <img
                                alt="Dissabte 1"
                                src={`${publicUrl}imatges/${
                                    state.foto.split("/")[1]
                                }/esdeveniments`}
                                className={classes.image}
                            />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Footer />
        </Page>
    );
};

export default SingleAgenda;

import React from "react";
import { withStyles } from "@material-ui/styles";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useNavigate } from "react-router";

const BootstrapButton = withStyles(
    (theme) => ({
        root: {
            boxShadow: "none",
            textTransform: "none",
            fontSize: 16,
            borderRadius: 30,
            border: "2px solid",
            borderColor: theme.palette.background.default,
            lineHeight: 1.5,
            backgroundColor: theme.palette.background.default,
            transition: "0.5s",
            "& .MuiTypography-h3": {
                color: "white",
            },
            "& .MuiTypography-body1": {
                color: "white",
            },
            padding: 15,
            "&:hover": {
                color: "white",
                border: "2px solid",
                borderColor: theme.palette.background.default,
                backgroundColor: "transparent",
                boxShadow: "none",
                "& .MuiTypography-h3": {
                    color: theme.palette.background.default,
                },
                "& .MuiTypography-body1": {
                    color: theme.palette.background.default,
                },
            },
            "&:active": {
                boxShadow: "none",
                backgroundColor: theme.palette.background.default,
                borderColor: theme.palette.background.default,
            },
            "&:focus": {
                boxShadow: "0 0 0 0.1rem ",
            },
        },
        label: {
            color: "white",
        },
    }),
    { withTheme: true }
)(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        paddingLeft: 40,
        paddingRight: 40,
    },
    button: {
        flexDirection: "columns",
    },
}));

export default function MyButton(props) {
    const { title, subtitle, fullWidth, url, external, ...rest } = props;
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div>
            <BootstrapButton
                variant="contained"
                color="primary"
                disableRipple
                className={classes.margin}
                fullWidth={fullWidth}
                onClick={() => (!external ? navigate(url) : "")}
                href={external ? url : ""}
                {...rest}
            >
                <Box className={classes.button}>
                    <Typography variant="h3">{title}</Typography>
                    <Typography variant="body1">{subtitle}</Typography>
                </Box>
            </BootstrapButton>
        </div>
    );
}

import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import Divide from "../../components/Divide";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { Element, animateScroll } from "react-scroll";
import MyButtonMini from "../../components/MyButtonMini";
import Objecte from "./Objecte";
import Reglaments from "./Reglaments";
import Normativa from "./Normativa";
import Acords from "./Acords";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        paddingBottom: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    main2: {
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        paddingBottom: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    icon: {
        cursor: "pointer",
        display: "flex",
        paddingRight: 15,
    },
    buttons: {
        display: "flex",
        flexWrap: "wrap",
    },
}));
const Patronat = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [section, setSection] = useState({
        nom: "objecte",
        titol: t("Objecte i composició"),
        component: <Objecte />,
    });
    const [{ opacity }, set] = useSpring(() => ({
        opacity: 1,
        onRest: () => {
            setTimeout(set.start({ opacity: 1 }), 2000);
        },
    }));
    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const changeSection = async (nom, titol, component) => {
        const onRest = () => {
            setSection({ nom, titol, component });
        };
        set.start({ opacity: 0, onRest });
        animateScroll.scrollTo(matches ? 300 : 650, {
            smooth: true,
            duration: 800,
        });
    };

    return (
        <Page title={t("Patronat")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t("Patronat")} />
                            <Grid className={classes.buttons}>
                                <Grid className={classes.icon}>
                                    <MyButtonMini
                                        title={t("Objecte i composició")}
                                        onClick={() =>
                                            changeSection(
                                                "objecte",
                                                t("Objecte i composició"),
                                                <Objecte />
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid className={classes.icon}>
                                    <MyButtonMini
                                        title={t("Normativa")}
                                        onClick={() =>
                                            changeSection(
                                                "normativa",
                                                t("Normativa"),
                                                <Normativa />
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid className={classes.icon}>
                                    <MyButtonMini
                                        title={t("Acords de les sessions")}
                                        onClick={() =>
                                            changeSection(
                                                "acords",
                                                t("Acords de les sessions"),
                                                <Acords />
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid className={classes.icon}>
                                    <MyButtonMini
                                        title={t(
                                            "Reglaments de règim intern de les comparses"
                                        )}
                                        onClick={() =>
                                            changeSection(
                                                "reglaments",
                                                t(
                                                    "Reglaments de règim intern de les comparses"
                                                ),
                                                <Reglaments />
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation animateIn="fadeIn">
                    <Container className={classes.root}>
                        <animated.div style={{ opacity }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h2">
                                        {section.titol}
                                    </Typography>
                                    <Divide color />
                                    <Box py={3} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={10}>
                                <Grid item md={12}>
                                    <Typography
                                        variant="body1"
                                        style={{ whiteSpace: "pre-line" }}
                                    >
                                        {t("text_" + section.nom)}
                                    </Typography>
                                    <Box py={3} />
                                    {section.component}
                                </Grid>
                            </Grid>
                        </animated.div>
                    </Container>
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default Patronat;

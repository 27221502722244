import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const Page = forwardRef(
    (
        {
            children,
            title = "La Patum",
            description = "Pàgina web oficial de La Patum de Berga",
            ...rest
        },
        ref
    ) => {
        return (
            <div ref={ref} {...rest}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description}></meta>
                </Helmet>
                {children}
            </div>
        );
    }
);

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default Page;

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ReactComponent as Turcs } from "../theme/icons/turcs.svg";
import { ReactComponent as Maces } from "../theme/icons/masses.svg";
import { ReactComponent as Angels } from "../theme/icons/angels.svg";
import { ReactComponent as Guites } from "../theme/icons/guites.svg";
import { ReactComponent as Aliga } from "../theme/icons/aliga.svg";
import { ReactComponent as NansVells } from "../theme/icons/nans-vells.svg";
import { ReactComponent as Gegants } from "../theme/icons/gegants.svg";
import { ReactComponent as NansNous } from "../theme/icons/nans-nous.svg";
import { ReactComponent as Tirabols } from "../theme/icons/comparses.svg";
import { ReactComponent as Plens } from "../theme/icons/plens.svg";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    wrap: {
        marginTop: 20,
        marginBottom: 20,
        textAlign: "center",
    },
}));
const ComparsesOrdre = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box
            style={{
                backgroundColor: "white",
                paddingBottom: 30,
            }}
        >
            <Typography variant="h4">
                {t("Ordre d'aparició de les comparses")}
            </Typography>
            <Box p={1}></Box>

            <Grid container>
                <Grid item xs={3} className={classes.wrap}>
                    <Typography variant="body1">1r</Typography>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Turcs}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Maces}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Angels}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Guites}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Aliga}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansVells}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Gegants}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansNous}
                            color={"#d9594e"}
                        />
                    </Box>
                </Grid>
                <Grid item xs={3} className={classes.wrap}>
                    <Typography variant="body1">2n</Typography>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Turcs}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Maces}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Angels}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Guites}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Aliga}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansVells}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Gegants}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansNous}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Plens}
                            color={"#d9594e"}
                        />
                    </Box>
                </Grid>
                <Grid item xs={3} className={classes.wrap}>
                    <Typography variant="body1">3r</Typography>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Turcs}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Maces}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Angels}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Guites}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Aliga}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansVells}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Gegants}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansNous}
                            color={"#d9594e"}
                        />
                    </Box>
                </Grid>
                <Grid item xs={3} className={classes.wrap}>
                    <Typography variant="body1">4t</Typography>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Turcs}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Maces}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Angels}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Guites}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Aliga}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansVells}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Gegants}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={NansNous}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Plens}
                            color={"#d9594e"}
                        />
                    </Box>
                    <Box my={2}>
                        <Logo
                            width={"100%"}
                            height={60}
                            icon={Tirabols}
                            color={"#d9594e"}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ComparsesOrdre;

import axios from 'axios';

const url = 'https://api.lapadrina.net/api/';
const publicUrl = 'https://api.lapadrina.net/';

const getData = async () => {
    let message;
    let data;
    await axios
        .get(url + 'data')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getPremsa = async () => {
    let message;
    let data;
    await axios
        .get(url + 'dossiers')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getNoticies = async (lang, page, cerca) => {
    let message;
    let data;
    const params = cerca
        ? url + 'noticies/' + lang + '?page=' + page + '&cerca=' + cerca
        : url + 'noticies/' + lang + '?page=' + page;
    await axios
        .get(params)
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getMiniNoticies = async (lang) => {
    let message;
    let data;
    await axios
        .get(url + 'miniNoticies/' + lang)
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getEsdeveniments = async (lang) => {
    let message;
    let data;
    await axios
        .get(url + 'esdeveniments/' + lang)
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getMiniEsdeveniments = async (lang) => {
    let message;
    let data;
    await axios
        .get(url + 'miniEsdeveniments/' + lang)
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getPatumAny = async (lang) => {
    let message;
    let data;
    await axios
        .get(url + 'esdevenimentsAny/' + lang)
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getAdministradors = async () => {
    let message;
    let data;
    await axios
        .get(url + 'administradors')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getPatumaires = async () => {
    let message;
    let data;
    await axios
        .get(url + 'patumaires')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getConsells = async (lang) => {
    let message;
    let data;
    await axios
        .get(url + 'recomanacions/' + lang)
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getVides = async () => {
    let message;
    let vides;
    await axios
        .get(url + 'vides')
        .then((response) => {
            if (response.status === 200) {
                vides = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { vides, message };
};

const getObjectes = async () => {
    let message;
    let objectes;
    await axios
        .get(url + 'objectes')
        .then((response) => {
            if (response.status === 200) {
                objectes = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { objectes, message };
};

const getEstatuts = async () => {
    let message;
    let data;
    await axios
        .get(url + 'estatuts')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getAcords = async () => {
    let message;
    let data;
    await axios
        .get(url + 'acords')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getReglaments = async () => {
    let message;
    let data;
    await axios
        .get(url + 'reglaments')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getFormularis = async () => {
    let message;
    let data;
    await axios
        .get(url + 'formularis')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

const getAcreditacions = async () => {
    let message;
    let data;
    await axios
        .get(url + 'acreditacions')
        .then((response) => {
            if (response.status === 200) {
                data = response.data;
                message = 'Obtingut amb èxit';
            }
            if (
                response.data.status === 'failed' &&
                response.data.success === undefined
            ) {
                message = "No s'han trobat";
            }
        })
        .catch((error) => {
            console.log(error);
        });

    return { data, message };
};

export {
    publicUrl,
    getData,
    getPremsa,
    getMiniNoticies,
    getNoticies,
    getEsdeveniments,
    getMiniEsdeveniments,
    getAdministradors,
    getPatumaires,
    getConsells,
    getVides,
    getObjectes,
    getEstatuts,
    getAcords,
    getReglaments,
    getFormularis,
    getAcreditacions,
    getPatumAny,
};

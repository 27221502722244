import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import {
    Box,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getObjectes } from "../../database/API";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "white",
        fontFamily: "Varela Round",
        textTransform: "uppercase",
        color: theme.palette.text.primary,
        borderBottom: "2px solid #d9594e",
    },
    body: {
        fontFamily: "Varela Round",
        color: theme.palette.text.secondary,
        borderBottom: "1px solid #d9594e",
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "white",
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingLeft: 20,
        paddingRight: 20,
    },
    button: {
        flexDirection: "columns",
    },
}));

export default function MyButtonMini() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [objectes, setObjectes] = useState([]);

    useEffect(() => {
        const get = async () => {
            const { objectes } = await getObjectes();
            setObjectes(objectes);
        };
        get();
    }, []);

    return (
        <Box>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t("Càrrec")}</StyledTableCell>
                            <StyledTableCell align="right">
                                {t("Nom i cognoms del o la titular")}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {t("Nom i cognoms del o la suplent")}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {objectes?.map((row) => (
                            <StyledTableRow key={row.nom}>
                                <StyledTableCell component="th" scope="row">
                                    {row.carrec}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {row.nom}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {row.suplent}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

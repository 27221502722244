import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Turcs } from '../theme/icons/turcs.svg';
import { ReactComponent as Maces } from '../theme/icons/masses.svg';
import { ReactComponent as Angels } from '../theme/icons/angels.svg';
import { ReactComponent as Guites } from '../theme/icons/guites.svg';
import { ReactComponent as Aliga } from '../theme/icons/aliga.svg';
import { ReactComponent as NansVells } from '../theme/icons/nans-vells.svg';
import { ReactComponent as Gegants } from '../theme/icons/gegants.svg';
import { ReactComponent as NansNous } from '../theme/icons/nans-nous.svg';
import { ReactComponent as Tirabols } from '../theme/icons/comparses.svg';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
    textComparses: {
        textAlign: 'center',
    },
    wrap: {
        marginTop: 20,
        marginBottom: 20,
        cursor: 'pointer',
    },
}));
const ComparsesOrdre = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box
            my={10}
            style={{
                backgroundColor: 'white',
                paddingTop: 20,
                paddingBottom: 30,
                position: 'relative',
            }}
        >
            <Typography variant="h4">
                {t("Ordre d'aparició de les comparses")}
            </Typography>
            <Box p={1}></Box>

            <Grid container>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'turcs',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={Turcs}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Els Turcs i Cavallets
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'maces',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={Maces}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Les Maces
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'angels',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={Angels}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Els Àngels
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'guites',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={Guites}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Les Guites
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'aliga',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={Aliga}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        L'Àliga
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'nansVells',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={NansVells}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Els Nans Vells
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'gegants',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={Gegants}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Els Gegants
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md
                    className={classes.wrap}
                    onClick={() =>
                        navigate('/protagonistes/comparses', {
                            state: 'nansNous',
                        })
                    }
                >
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={NansNous}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Els Nans Nous
                    </Typography>
                </Grid>
                <Grid item xs={12} md className={classes.wrap}>
                    <Logo
                        width={'100%'}
                        height={100}
                        icon={Tirabols}
                        color={'#d9594e'}
                    />
                    <Box p={1} />
                    <Typography
                        variant="body1"
                        className={classes.textComparses}
                    >
                        Tirabols
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ComparsesOrdre;

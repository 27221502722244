import React from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import MyButton from "../../../components/MyButton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
        paddingBottom: 100,
    },
    text: {
        textAlign: "center",
    },
}));

const QueEsInici = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:960px)");

    return (
        <Box style={{ height: matches && "90vh" }} className={classes.main}>
            <ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
                <Container className={classes.root}>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <Typography variant="h1" style={{ color: "#fff" }}>
                                {t("que_es")}
                            </Typography>
                            <Box pt={5} />
                            <Typography variant="h3" style={{ color: "#fff" }}>
                                {t("festa")}
                            </Typography>
                            <Box pt={2} />
                            <Typography
                                variant="body1"
                                style={{ color: "#fff" }}
                            >
                                {t("que_es_1")}
                            </Typography>
                            <Box pt={2} />
                            <Typography
                                variant="body1"
                                style={{ color: "#fff" }}
                            >
                                {t("que_es_2")}
                            </Typography>
                            <Box pt={2} />
                            <Typography
                                variant="body1"
                                style={{ color: "#fff" }}
                            >
                                {t("que_es_3")}
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{ width: "100%" }}>
                            <MyButton
                                title={t("Dimecres de Corpus")}
                                subtitle={t(
                                    "Passacarrers amb salts de Patum i Tirabols"
                                )}
                                fullWidth={true}
                                url={"/que-es/dimecres"}
                            />
                            <MyButton
                                title={t("Dia de Corpus (dijous)")}
                                subtitle={t(
                                    "Patum de Lluïment i Patum completa"
                                )}
                                fullWidth={true}
                                url={"/que-es/dijous"}
                            />
                            <MyButton
                                title={t("Divendres de Corpus")}
                                subtitle={t("patum_infantil")}
                                fullWidth={true}
                                url={"/que-es/divendres"}
                            />
                            <MyButton
                                title={t("Dissabte de Corpus")}
                                subtitle={t(
                                    "Passacarrers amb salts de Patum i Tirabols"
                                )}
                                fullWidth={true}
                                url={"/que-es/dissabte"}
                            />
                            <MyButton
                                title={t("Diumenge de Corpus")}
                                subtitle={t(
                                    "Patum de Lluïment i Patum completa"
                                )}
                                fullWidth={true}
                                url={"/que-es/diumenge"}
                            />
                        </Grid>
                    </Grid>
                    <Box textAlign="center" py={5}>
                        <MyButton
                            subtitle={t("Saber-ne més")}
                            url={"/que-es"}
                        />
                    </Box>
                </Container>
            </ScrollAnimation>
        </Box>
    );
};

export default QueEsInici;

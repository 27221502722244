import React from "react";
import { Box, Divider } from "@material-ui/core";

const Divide = (props) => {
    const { type, color } = props;

    if (type === "alt") {
        return (
            <Divider
                style={{
                    backgroundColor: "#d9594e",
                    marginTop: 4,
                    marginBottom: 15,
                    height: 2,
                }}
            />
        );
    }
    return (
        <Box textAlign="center" py={3}>
            <Divider
                style={
                    color
                        ? { height: 2, backgroundColor: "#d9594e" }
                        : { height: 2, backgroundColor: "white" }
                }
            />
        </Box>
    );
};

export default Divide;

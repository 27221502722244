import { Container, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import { ReactComponent as Tabal } from "../theme/icons/tabal.svg";
import { ReactComponent as Turcs } from "../theme/icons/turcs.svg";
import { ReactComponent as Maces } from "../theme/icons/masses.svg";
import { ReactComponent as Angels } from "../theme/icons/angels.svg";
import { ReactComponent as Guites } from "../theme/icons/guites.svg";
import { ReactComponent as Aliga } from "../theme/icons/aliga.svg";
import { ReactComponent as NansVells } from "../theme/icons/nans-vells.svg";
import { ReactComponent as Gegants } from "../theme/icons/gegants.svg";
import { ReactComponent as NansNous } from "../theme/icons/nans-nous.svg";
import { ReactComponent as Plens } from "../theme/icons/plens.svg";
import { ReactComponent as TabalSol } from "../theme/icons/tabal-sol.svg";
import { ReactComponent as TurcsSol } from "../theme/icons/turcs-sol.svg";
import { ReactComponent as MacesSol } from "../theme/icons/maces-sol.svg";
import { ReactComponent as AngelsSol } from "../theme/icons/angels-sol.svg";
import { ReactComponent as GuitesSol } from "../theme/icons/guites-sol.svg";
import { ReactComponent as AligaSol } from "../theme/icons/aliga-sol.svg";
import { ReactComponent as NansVellsSol } from "../theme/icons/nans-vells-sol.svg";
import { ReactComponent as GegantsSol } from "../theme/icons/gegants-sol.svg";
import { ReactComponent as NansNousSol } from "../theme/icons/nans-nous-sol.svg";
import { ReactComponent as PlensSol } from "../theme/icons/plens-sol.svg";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";

const useStyles = makeStyles((theme) => ({
    textComparses: {
        textAlign: "center",
    },
    main: {
        backgroundColor: theme.palette.background.default,
    },
    onTop: {
        display: "none",
    },
    onScroll: {
        position: "fixed",
        backgroundColor: theme.palette.background.default,
        left: 0,
        zIndex: 10,
        width: "100%",
        padding: 20,
    },
    icon: {
        cursor: "pointer",
    },
}));
const ComparsesOrdre = ({ changeComparsa }) => {
    const classes = useStyles();
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [{ top }, set] = useSpring(() => ({
        top: 0,
    }));

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const handleScroll = () => {
        setScrollY(window.scrollY);
        if (scrollY > 400) {
            set.start({ top: 84 });
        } else {
            set.start({ top: -20 });
        }
    };

    return (
        <animated.div
            className={scrollY !== 0 ? classes.onScroll : classes.onTop}
            style={{ top }}
        >
            <Container className={classes.main}>
                <Grid container>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Tabal}
                            color="white"
                            onClick={() =>
                                changeComparsa("tabal", TabalSol, "tabal")
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Turcs}
                            color="white"
                            onClick={() =>
                                changeComparsa(
                                    "turcs i cavallets",
                                    TurcsSol,
                                    "turcs"
                                )
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Maces}
                            color="white"
                            onClick={() =>
                                changeComparsa("maces", MacesSol, "maces")
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Angels}
                            color="white"
                            onClick={() =>
                                changeComparsa("angels", AngelsSol, "angels")
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Guites}
                            color="white"
                            onClick={() =>
                                changeComparsa("guites", GuitesSol, "guites")
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Aliga}
                            color="white"
                            onClick={() =>
                                changeComparsa("aliga", AligaSol, "aliga")
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={NansVells}
                            color="white"
                            onClick={() =>
                                changeComparsa(
                                    "nans vells",
                                    NansVellsSol,
                                    "nans-vells"
                                )
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Gegants}
                            color="white"
                            onClick={() =>
                                changeComparsa("gegants", GegantsSol, "gegants")
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={NansNous}
                            color="white"
                            onClick={() =>
                                changeComparsa(
                                    "nans nous",
                                    NansNousSol,
                                    "nans-nous"
                                )
                            }
                        />
                    </Grid>
                    <Grid xs={12} md className={classes.icon}>
                        <Logo
                            width={"100%"}
                            height={80}
                            hover
                            icon={Plens}
                            color="white"
                            onClick={() =>
                                changeComparsa("plens", PlensSol, "plens")
                            }
                        />
                    </Grid>
                </Grid>
            </Container>
        </animated.div>
    );
};

export default ComparsesOrdre;

import React from 'react';
import Home from './pages/Home/Home';
import QueEs from './pages/QueEs/QueEs';
import Dimecres from './pages/QueEs/Dimecres';
import Dijous from './pages/QueEs/Dijous';
import Divendres from './pages/QueEs/Divendres';
import Dissabte from './pages/QueEs/Dissabte';
import Diumenge from './pages/QueEs/Diumenge';
import Noticies from './pages/Noticies/Noticies';
import SingleNoticia from './pages/Noticies/SingleNoticia';
import SingleAgenda from './pages/Agenda/SingleAgenda';
import Agenda from './pages/Agenda/Agenda';
import Comparses from './pages/Protagonistes/Comparses';
import Musica from './pages/Protagonistes/Musica';
import PatumInfantil from './pages/Protagonistes/PatumInfantil';
import Administradors from './pages/Protagonistes/Administradors';
import Patumaires from './pages/Protagonistes/Patumaires';
import Premsa from './pages/Premsa/Premsa';
import Acreditacions from './pages/Premsa/Acreditacions';
import Patronat from './pages/Patronat/Patronat';
import Historia from './pages/Historia';
import Glossari from './pages/Altres/Glossari';
import Consells from './pages/Altres/Consells';
import Layout from './layouts/NavBar';
import Vides from './pages/Vides/Vides';
import Formularis from './pages/Formularis';
import PatumAny from './pages/PatumAny/PatumAny';

const routes = () => [
    {
        path: '/',
        element: <Layout />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/que-es', element: <QueEs /> },
            { path: '/que-es/dimecres', element: <Dimecres /> },
            { path: '/que-es/dijous', element: <Dijous /> },
            { path: '/que-es/divendres', element: <Divendres /> },
            { path: '/que-es/dissabte', element: <Dissabte /> },
            { path: '/que-es/diumenge', element: <Diumenge /> },
            { path: '/actualitat/noticies', element: <Noticies /> },
            { path: '/actualitat/noticies/:key', element: <SingleNoticia /> },
            { path: '/actualitat/agenda', element: <Agenda /> },
            { path: '/actualitat/agenda/:key', element: <SingleAgenda /> },
            { path: '/patum-tot-lany', element: <PatumAny /> },
            { path: '/protagonistes/comparses', element: <Comparses /> },
            { path: '/protagonistes/musica', element: <Musica /> },
            {
                path: '/protagonistes/patum-infantil',
                element: <PatumInfantil />,
            },
            {
                path: '/protagonistes/administradors',
                element: <Administradors />,
            },
            {
                path: '/protagonistes/patumaires',
                element: <Patumaires />,
            },
            { path: '/premsa/dossiers', element: <Premsa /> },
            { path: '/premsa/acreditacions', element: <Acreditacions /> },
            { path: '/patronat', element: <Patronat /> },
            { path: '/historia', element: <Historia /> },
            { path: '/vides-de-patum', element: <Vides /> },
            { path: '/glossari', element: <Glossari /> },
            { path: '/consells', element: <Consells /> },
            { path: '/formularis', element: <Formularis /> },
        ],
    },
];

export default routes;

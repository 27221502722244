import {
    Box,
    Container,
    Divider,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import { ReactComponent as Patum } from "../../theme/icons/patumaires-sol.svg";
import theme from "../../theme";
import Divide from "../../components/Divide";
import { Element } from "react-scroll";
import { getPatumaires } from "../../database/API";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
    },
    main: {
        paddingTop: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    main2: {
        paddingBottom: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        alignContent: "center",
    },
    mainM: {
        paddingTop: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
    image: {
        width: "100%",
    },
    titol: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 20,
        padding: 30,
    },
    patumaire: {
        backgroundColor: theme.palette.background.dark,
        borderRadius: 20,
        padding: 30,
    },
}));

const Patumaires = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [patum, setPatum] = useState();
    const [any, setAny] = useState("2021");
    const matches = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { data } = await getPatumaires();
            setPatum(data);
            setAny(data.any);
            console.log(data);
        };
        get();
    }, []);

    return (
        <Page title={t("Patumaires")}>
            <Box className={matches ? classes.main : classes.mainM}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Box>
                            <Header title={t("Patumaires")} />
                        </Box>
                    </Container>
                </ScrollAnimation>
            </Box>
            <Element name="content" className={classes.main2}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: "0.5s" }}
                >
                    <Container className={classes.root}>
                        <Grid container spacing={10}>
                            <Grid item xs={6}>
                                <Logo
                                    icon={Patum}
                                    color={theme.palette.text.primary}
                                    height={200}
                                    width={200}
                                />
                                <Box py={3} />
                            </Grid>
                        </Grid>
                        <Divide color />
                        <Box py={3} />

                        <Grid container spacing={10}>
                            <Grid item md={12}>
                                <Typography
                                    variant="body1"
                                    style={{ whiteSpace: "pre-line" }}
                                >
                                    {t("patumaires_1")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={10}>
                            <Grid item md={6}>
                                <Box className={classes.titol}>
                                    <Typography variant="h3">
                                        {t("Títol de Patumaire")} {any}
                                    </Typography>
                                    <Divider
                                        style={{
                                            backgroundColor: "white",
                                            marginBottom: 20,
                                            marginTop: 5,
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: "#FFFFFF",
                                            whiteSpace: "pre-line",
                                        }}
                                    >
                                        {patum?.titol}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={classes.patumaire}>
                                    <Typography
                                        variant="h3"
                                        style={{ color: "#d9594e" }}
                                    >
                                        {t("Patumaire d'honor")} {any}
                                    </Typography>
                                    <Divider
                                        style={{
                                            backgroundColor: "white",
                                            marginBottom: 20,
                                            marginTop: 5,
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: "#FFFFFF",
                                            whiteSpace: "pre-line",
                                        }}
                                    >
                                        {patum?.honor}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </ScrollAnimation>
            </Element>
            <Footer />
        </Page>
    );
};

export default Patumaires;

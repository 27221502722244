import { createMuiTheme, colors } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
    palette: {
        background: {
            dark: "#383838",
            default: "#d9594e",
            alt: colors.common.white,
        },
        primary: {
            main: "#d9594e",
            hover: "#d9594e90",
        },
        secondary: {
            main: "#E5E3DB",
        },
        danger: {
            main: colors.red[500],
            light: colors.red[300],
        },
        success: {
            main: colors.green[500],
        },
        text: {
            primary: "#d9594e",
            secondary: "#383838",
            danger: colors.red[500],
        },
        typography: {
            fontFamily: "Varela Round",
        },
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: "5rem",
                fontFamily: "Varela Round",
                textTransform: "uppercase",
                color: "#d9594e",
                [breakpoints.down("sm")]: {
                    fontSize: "3rem",
                },
                [breakpoints.down("xs")]: {
                    fontSize: "2rem",
                },
            },
            h2: {
                fontSize: "2.8rem",
                fontFamily: "Varela Round",
                textTransform: "uppercase",
                color: "#d9594e",
                [breakpoints.down("xs")]: {
                    fontSize: "1.5rem",
                },
            },
            h3: {
                fontSize: "1.5rem",
                fontFamily: "Varela Round",
                fontWeight: 200,
                color: "#424242",
                textTransform: "uppercase",
                [breakpoints.down("xs")]: {
                    fontSize: "1.2rem",
                },
            },
            h4: {
                fontSize: "1.3rem",
                fontFamily: "Varela Round",
                fontWeight: 200,
                color: "#d9594e",
                textTransform: "uppercase",
                [breakpoints.down("xs")]: {
                    fontSize: "1rem",
                },
            },
            h5: {
                fontSize: "2rem",
                fontFamily: "Varela Round",
                fontWeight: 200,
                color: "#d9594e",
                [breakpoints.down("xs")]: {
                    fontSize: "1.4rem",
                },
            },
            body1: {
                fontFamily: "Varela Round",
                fontSize: "1.1rem",
                color: "#383838",
                [breakpoints.down("xs")]: {
                    fontSize: "0.9rem",
                },
            },
            caption: {
                fontFamily: "Varela Round",
                fontSize: "0.8rem",
                color: "#383838",
            },
        },
    },
});

export default theme;

import { makeStyles } from "@material-ui/core";
import React from "react";
import AudioPlayer from "material-ui-audio-player";

const playerStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.background.default,
            borderRadius: 20,
            boxShadow: "none",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
            marginLeft: 0,
        },
        loopIcon: {
            color: "#3f51b5",
            "&.selected": {
                color: theme.palette.text.secondary,
            },
            "&:hover": {
                color: theme.palette.text.secondary,
            },
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        playIcon: {
            color: "white",
            "&:hover": {
                color: theme.palette.text.secondary,
            },
        },
        replayIcon: {
            color: "white",
        },
        pauseIcon: {
            color: "white",
            "&:hover": {
                color: theme.palette.text.secondary,
            },
        },
        volumeIcon: {
            color: "white",
            "&:hover": {
                color: theme.palette.text.secondary,
            },
        },
        volumeSlider: {
            color: theme.palette.background.default,
        },
        progressTime: {
            color: "white",
        },
        mainSlider: {
            color: "white",
            "& .MuiSlider-rail": {
                color: "white",
            },
            "& .MuiSlider-track": {
                color: "white",
            },
            "& .MuiSlider-thumb": {
                color: "white",
            },
        },
    };
});

const MusicaScreen = ({ song }) => {
    return (
        <AudioPlayer
            elevation={1}
            width="100%"
            variation="default"
            spacing={3}
            order="standart"
            preload="auto"
            src={song}
            useStyles={playerStyles}
        />
    );
};

export default MusicaScreen;

import React from "react";
import { Box, Container, Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        alignContent: "center",
    },
}));

const Divide = (props) => {
    const classes = useStyles();
    return (
        <Box className={classes.main}>
            <Container>
                <Divider style={{ backgroundColor: "white", height: 2 }} />
            </Container>
        </Box>
    );
};

export default Divide;

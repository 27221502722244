import { makeStyles, SvgIcon } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    icon: {
        flex: 1,
        alignSelf: "center",
    },
    hover: {
        "&:hover": {
            fill: theme.palette.text.secondary + "!important",
        },
    },
}));

const Icona = (props) => {
    const { width, icon, height, color, hover, ...rest } = props;
    const classes = useStyles();
    return (
        <SvgIcon
            className={clsx(classes.icon, hover && classes.hover)}
            style={{ width: width, height: height, fill: color }}
            component={icon}
            {...rest}
        />
    );
};

export default Icona;
